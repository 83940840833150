import * as R from "ramda";
import React, { useCallback, useEffect, useState } from 'react';

import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { FaCalculator, FaRedo } from 'react-icons/fa';
import { Button } from 'react-bootstrap';

import useAlertNotification from '../../../context/AlertNotificationContext';
import FechamentoApiService from '../../../services/api/FechamentoApiService';
import ErrorRequestService from '../../../services/errorRequest';
import ImpostoApiService from '../../../services/api/ImpostoApiService';
import BeneficioApiService from '../../../services/api/BeneficioApiService';

import Loading from '../../../components/Loading';
import PageContainer from '../../../components/PageContainer';
import ListLayout from '../../../components/ListLayout';

import { columns } from './FechamentoPageListInfo';
import FechamentoView from '../FechamentoView';
import FechamentoPageDetail from './FechamentoPageDetail';

import UtilService from '../../../services/util';

import './FechamentoPage.css';

const ORDER_DIR_NUM = { asc: 1, desc: -1 };

const INITIAL_PAGEPARAMS = {
    orderBy: 'nome',
    orderDir: 'asc'
};

function FechamentoPage({ onRowClick }) {
    // HOOKS
    const history = useHistory();
    const match = useRouteMatch();
    const location = useLocation();
    const { id, label } = location.state;
    const alert = useAlertNotification();

    // STATE
    const [partTimeColab, setPartTimeColab] = useState([]);
    const [rowsData, setRowsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isCalculando, setIsCalculando] = useState(false);
    const [filteredData, setFilteredData] = useState(null);
    const [orderBy, setOrderBy] = useState(INITIAL_PAGEPARAMS.orderBy);
    const [orderDir, setOrderDir] = useState(INITIAL_PAGEPARAMS.orderDir);

    // FUNCTIONS
    /** Define o clique sobre um registro da lista */
    const rowClick = (item) => {
        if (onRowClick) {
            onRowClick(item);
            return;
        }
        history.push(`${match.url}/${item.cod}`, item);
    }

    /** Adiciona click e atributos ao header */
    const getListHeader = () => {
        const headers = [];
        const defineOrder = (item) => {
            if (orderBy === item) {
                const newDir = (orderDir === 'asc') ? 'desc' : 'asc';
                setOrderDir(newDir);
                return;
            }
            setOrderBy(item);
        }
        columns.forEach(item => {
            const waySign = (ORDER_DIR_NUM[orderDir] > 0) ? '▴' : '▾';
            const orderInd = (orderBy === item.name)
                ? { ordered: `${waySign}` }
                : {};
            const head = {
                ...item,
                ...orderInd,
                // onClick: () => defineOrder(item.name),
            }
            headers.push(head);
        })
        return headers;
    }

    const defineDataList = () => {
        const sortRows = (a, b) => {
            if (a[orderBy] < b[orderBy]) return (-1 * ORDER_DIR_NUM[orderDir]);
            if (a[orderBy] > b[orderBy]) return (1 * ORDER_DIR_NUM[orderDir]);
        }

        const dataList = [];
        const rows = filteredData || rowsData || [];

        rows.sort(sortRows)
            .forEach(item => {
                const row = {
                    // onClick: () => rowClick(item),
                    data: columns.map(col => {
                        if (col.name === 'meioPeriodo') {
                            return {
                                actions: {
                                    type: "checkbox",
                                    checked: item[col.name],
                                    onclick: (item) => {
                                        const itemAdded = partTimeColab.find(obj => obj.id === item.id)

                                        if (itemAdded)
                                            itemAdded.checked = item.checked
                                        else
                                            partTimeColab.push(item)
                                    }
                                }
                            }
                        }

                        let value = item[col.name];

                        if (col.name !== 'nome' && col.name !== 'matricula')
                            value = value ? value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : '0';

                        return {
                            value,
                            className: col.className
                        }

                    }),
                };
                dataList.push(row);
            });
        return dataList;
    }

    /* Filtra a lista de dados */
    const filterList = (params) => {
        if (params.length <= 0) {
            setFilteredData(rowsData); // filtro local

            return
        }

        // APLICA FILTRO LOCAL
        let filterData = [];
        for (const param in params) {
            const filterParams = params[param];

            const filterColab = rowsData.filter(item => {
                const lwData = String(item[filterParams.column]).toLowerCase();
                const lwFilter = String(filterParams.filter).toLowerCase();

                return lwData.includes(lwFilter);
            });

            filterData = [...filterData, ...filterColab];
        }

        const notDuplicate = filterData.reduce((accum, curr) => {
            if (accum.length <= 0) return [curr];

            const notSame = accum.filter(item => item.matricula !== curr.matricula);
            return [...notSame, curr];
        }, []);

        setFilteredData(notDuplicate);
    }

    const exportDataInExcel = () => {
        const header = [
            [
                'id', 'Colaborador', 'Departamento', 'Função', 'Regime', 'Salário',
                'Salário Líquido', 'Custo Folha', 'Benefícios', 'Impostos',
                'Provisão 13º', 'Provisão Rescisão', 'Provisão Férias',
                'Custo Total', 'Custo Hora'
            ]
        ];

        const formatData = filteredData === null ? rowsData : filteredData;

        formatData.forEach(item => {
            item.beneficioTotal = parseFloat(item.beneficioTotal).toFixed(2).toLocaleString('pt-br');
            item.custoHora = parseFloat(item.custoHora).toFixed(2).toLocaleString('pt-br');
            item.custoTotal = parseFloat(item.custoTotal).toFixed(2).toLocaleString('pt-br');
            item.impostoTotal = parseFloat(item.impostoTotal).toFixed(2).toLocaleString('pt-br');
            item.provisaoDecimoTerceiro = parseFloat(item.provisaoDecimoTerceiro).toFixed(2).toLocaleString('pt-br');
            item.provisaoFerias = parseFloat(item.provisaoFerias).toFixed(2).toLocaleString('pt-br');
            item.provisaoRescisao = parseFloat(item.provisaoRescisao).toFixed(2).toLocaleString('pt-br');
            item.salarioBase = parseFloat(item.salarioBase).toFixed(2).toLocaleString('pt-br');
            item.salarioFilial = parseFloat(item.salarioFilial).toFixed(2).toLocaleString('pt-br');
            item.salarioLiquido = parseFloat(item.salarioLiquido).toFixed(2).toLocaleString('pt-br');
            item.meioPeriodo = item.meioPeriodo === 0 ? 'Não' : 'Sim';
        })

        UtilService.exportXls(formatData, label, header);
    }

    const formatFechamentoResult = () => {
        const formatData = filteredData === null ? rowsData : filteredData;

        const initial = {
            custoTTotal: 0,
            beneficioTTotal: 0,
            custoHHTotal: 0,
            impostoTTotal: 0,
            provisaoDecimoTerceiroTotal: 0,
            provisaoFeriasTotal: 0,
            provisaoRescisaoTotal: 0,
            salarioBaseTotal: 0,
            salarioFilialTotal: 0,
            salarioLiquidoTotal: 0,
            quantidadeColabs: 0
        }

        const calculated = formatData.reduce((accum, curr) => {
            return {
                custoTTotal: R.sum([accum.custoTTotal, curr.custoTotal]),
                beneficioTTotal: R.sum([accum.beneficioTTotal, curr.beneficioTotal]),
                custoHHTotal: R.sum([accum.custoHHTotal, curr.custoHora]),
                impostoTTotal: R.sum([accum.impostoTTotal, curr.impostoTotal]),
                provisaoDecimoTerceiroTotal: R.sum([accum.provisaoDecimoTerceiroTotal, curr.provisaoDecimoTerceiro]),
                provisaoFeriasTotal: R.sum([accum.provisaoFeriasTotal, curr.provisaoFerias]),
                provisaoRescisaoTotal: R.sum([accum.provisaoRescisaoTotal, curr.provisaoRescisao]),
                salarioBaseTotal: R.sum([accum.salarioBaseTotal, curr.salarioBase]),
                salarioFilialTotal: R.sum([accum.salarioFilialTotal, curr.salarioFilial]),
                salarioLiquidoTotal: R.sum([accum.salarioLiquidoTotal, curr.salarioLiquido]),
                quantidadeColabs: R.sum([accum.quantidadeColabs, 1])
            }
        }, initial);

        return calculated
    }

    // FUNCTIONS
    const handleCalculate = useCallback(async (isRecalculate = false) => {
        const confirma = !isRecalculate || window.confirm(
            'Os cálculos totais gerados anteriormente para este fechamento serão DESCARTADOS.'
            + '\nNovos cálculos serão gerados com base nos valores atuais de impostos, dados de colaboradores e seus benefícos.'
            + '\n\n*** ATENÇÃO: Esta operação não é reversível! ***\n'
            + '\nDeseja realmente continuar?'
        );
        if (confirma) {
            setIsCalculando(true);
            try {
                const [impCalc, benefCalc] = await Promise.all([
                    ImpostoApiService.calculate(id, isRecalculate).then(r => r.data),
                    BeneficioApiService.calculate(id, isRecalculate).then(r => r.data)
                ]);

                if (impCalc && benefCalc) {
                    await FechamentoApiService.calculoCustoHora(id, partTimeColab).then(r => r.data);

                    const custosColab = await FechamentoApiService.getCustoHH(id).then(r => r.data);

                    setRowsData(custosColab);

                    history.replace(match.url, location.state);
                }
                console.log('partTimeColab', partTimeColab);
            }
            catch (err) {
                const errorService = new ErrorRequestService(err);
                const errorMsg = errorService.getErrors();
                console.log(errorMsg);
                alert.error(errorMsg);
            }
            finally {
                setIsCalculando(false);
            }
        }
    }, [id, history, location.state, match.url]);

    const onStart = useCallback(async () => {
        setIsLoading(true);

        try {
            const custosColab = await FechamentoApiService.getCustoHH(id).then(r => r.data);

            setRowsData(custosColab);
        }
        catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();
            console.log(errorMsg);
            alert.error(errorMsg);
        }
        finally {
            setIsLoading(false);
        }
    }, [id]);

    // USEEFFECTS
    useEffect(onStart, []);

    const actions = [
        ...(rowsData.length
            ? [{
                ...(isCalculando
                    ? { label: 'Recalculando...' }
                    : { label: 'Recalcular', icon: <FaRedo /> }
                ),
                color: 'info',
                type: 'button',
                disabled: isCalculando,
                onClick: !isCalculando ? () => handleCalculate(true) : null
            }]
            : [{
                ...(isCalculando
                    ? { label: 'Calculando...' }
                    : { label: 'Calcular', icon: <FaCalculator /> }
                ),
                color: 'dark',
                type: 'button',
                disabled: isCalculando,
                onClick: !isCalculando ? () => handleCalculate() : null
            }]
        )
    ]

    return (
        <div className='fechamento-container'>
            {isLoading && (
                <PageContainer title={`Fechamento ${label || ''}`}>
                    <Loading message="Carregando..." />
                </PageContainer>
            )}

            {!isLoading && (<>
                <FechamentoView
                    extraActions={actions}
                    key={`fecham-view-${location.key}`}
                />

                <Button
                    style={{ marginBottom: 20 }}
                    onClick={exportDataInExcel}
                >Exportar</Button>

                <FechamentoPageDetail fechamento={formatFechamentoResult()} />

                <ListLayout
                    columns={getListHeader()}
                    rows={defineDataList()}
                    onSubmitFilter={filterList}
                    multiFilter
                />
            </>)}
        </div>
    )
}

export default FechamentoPage
