import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz'
import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Col, Row, Button, Pagination } from 'react-bootstrap';

import { FormBuilder, FormStateBuilder } from '../../../components/FormBuilder';

import PageContainer from '../../../components/PageContainer';
import Loading from '../../../components/Loading';

import ListLayout from '../../../components/ListLayout';
import IssuesListInfo from "./IssuesListInfo";
import ColaboradoresListInfo from "./ColaboradoresListInfo";
import FiltroInfo from "./FiltroInfo";
import HorasDetail from "./HorasDetail";
import HeaderDetailColaboradores from "./HeaderDetailColaboradores";
import HeaderDetailTarefas from "./HeaderDetailTarefas";

import ErrorRequestService from '../../../services/errorRequest';
import ProjetosApiService from '../../../services/api/ProjetosApiService';
import { ProjetoViewHelpModal } from './ProjetoViewHelpModal';
import CustomModal from '../../../components/CustomModal';

function ProjetoView() {
    const match = useRouteMatch();

    const { key } = match.params;

    const [dataProjectDetail, setDataProjectDetail] = useState(null);

    const [isLoading, setIsLoading] = useState(true);
    const [formFields, setFormFields] = useState(null);
    const [formData, setFormData] = useState(null);
    const [origData, setOrginData] = useState(null);

    const [initialDate, setInitialDate] = useState(new Date());
    const [finalDate, setFinalDate] = useState(new Date());

    const [itemsPageIssues, setItemsPageIssues] = useState([]);
    const [timesheet, setTimesheet] = useState([]);

    const handlerFilter = async () => {
        const startAt = 0;
        const initialDateAdjusted = format(utcToZonedTime(initialDate,  'America/Sao_Paulo' ), 'dd/MM/yyyy');
        const finalDateAdjusted = format(utcToZonedTime(finalDate,  'America/Sao_Paulo' ), 'dd/MM/yyyy');

        try {
            setIsLoading(true);

            const resp = (await ProjetosApiService.getDetailProject(key, initialDateAdjusted, finalDateAdjusted)).data;

            buildPagination(resp.pages)
            setDataProjectDetail(resp);
            setTimesheet(resp.timesheet[startAt]);

        } catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();

            console.log(errorMsg);

            alert(errorMsg);
        } finally {
            setIsLoading(false);
        }
    }

    const getDataList = useCallback(async () => {
        try {
            setIsLoading(true);

            const formFields = FiltroInfo.getFormFields();
            const formData = FormStateBuilder(formFields);

            setOrginData(formData);
            setFormFields(formFields);
            setFormData(formData);

            await handlerFilter();
        } catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();

            console.log(errorMsg);

            alert(errorMsg);

        } finally {
            setIsLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const buildPagination = (pages, startAt = 0) => {
        const arrPagesNumber = [];

        for (let number = 0; number < pages; number++) {
            arrPagesNumber.push(
                <Pagination.Item key={number} active={number === +startAt}>
                    {number}
                </Pagination.Item>,
            );
        }

        setItemsPageIssues(arrPagesNumber);
    }

    const navPagination = (i) => {
        if (isNaN(i)) return;

        setTimesheet(dataProjectDetail.timesheet[+i]);
        buildPagination(dataProjectDetail.pages, i);
    }

    const onChange = async (ev) => {
        const { value, name } = ev.target;
        const newform = await { ...formData, [name]: value };

        setInitialDate(newform.initialDate && newform.initialDate);
        setFinalDate(newform.finalDate && newform.finalDate);

        setFormData(newform);
    }

    const getIssuesRows = useCallback(() => {
        if (!dataProjectDetail) return [];
        const rows = timesheet.map(item => {
            const row = {
                data: IssuesListInfo.columns.map(col => {
                    let value = item[col.name];

                    if (`${item.timeLogged}`.split('.').length > 1) {
                        item.timeLogged = item.timeLogged.toLocaleString('en-US', { hour: '2-digit' })
                    }

                    return {
                        value,
                        className: col.className
                    }
                })
            }
            return row;
        });
        return rows;
    }, [dataProjectDetail, timesheet]);

    const getColaboradoresRows = useCallback(() => {
        if (!dataProjectDetail) return [];

        const rows = dataProjectDetail.timeSpentUser.map(item => {
            const row = {
                data: ColaboradoresListInfo.columns.map(col => {
                    let value = item[col.name];

                    if (`${item.timeLogged}`.split('.').length > 1) {
                        item.timeLogged = item.timeLogged.toLocaleString('en-US', { hour: '2-digit' })
                    }

                    item.custoHH = item.custoHH.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
                    item.custoTotal = item.custoTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

                    return {
                        value,
                        className: col.className
                    }
                })
            }
            return row;
        });
        return rows;
    }, [dataProjectDetail]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getDataList, []);

    const title = `Projeto ${key}`;

    return (
        <PageContainer
            title={title}
            infoButton={<ProjetoViewHelpModal />}
        >
            {formFields &&
                <Row>
                    <Col>
                        <FormBuilder
                            formFields={formFields}
                            formData={formData}
                            onChange={onChange}
                            initialValues={origData}
                            disabledForm={isLoading}
                        />
                        <Button variant="outline-primary" onClick={() => handlerFilter()} disabled={isLoading}>Filtrar</Button>
                    </Col>

                    <Col>
                        <HorasDetail
                            isLoading={isLoading}
                            dataProjectDetail={dataProjectDetail}
                            project={key}
                            periodoInicio={format(new Date(initialDate), 'dd-LLL-yyyy')}
                            periodoFim={format(new Date(finalDate), 'dd-LLL-yyyy')}
                        />
                        <CustomModal />
                    </Col>
                </Row>
            }

            {isLoading
                ? <Loading message="Carregando..." />
                :
                <>
                    <HeaderDetailColaboradores dataProjectDetail={dataProjectDetail} />
                    <ListLayout columns={ColaboradoresListInfo.columns} rows={getColaboradoresRows()} />

                    <HeaderDetailTarefas timesheet={timesheet} />
                    <ListLayout columns={IssuesListInfo.columns} rows={getIssuesRows()} />
                    <Pagination onClick={(e) => navPagination(e.target.textContent)}>{itemsPageIssues}</Pagination>
                </>
            }
        </PageContainer>
    )
}

export default ProjetoView;
