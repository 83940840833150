import api from "./api";

const BASE_ENDPOINT = '/relatorio';

/**
 * Traz uma lista de colaboradores e seus benefícios 
 * totais (sintetico) ou em detalhes (analítico) 
 * @param {{
 *  apresentacao: 'analitico' | 'sintetico',
 *  filtros
 * }}
 */
const getColaboradorBeneficio = ({ apresentacao, ...filtros }) => {
    return api.get(
        `${BASE_ENDPOINT}/colaborador-beneficio`,
        { params: { apresentacao, filtros } }
    );
}

const RelatorioApiService = {
    getColaboradorBeneficio
}

export default RelatorioApiService;