import React from 'react';
import PropTypes from 'prop-types';
import { Table, Form } from 'react-bootstrap';
import NothingToShow from '../NothingToShow';
import ListTopbar from './ListTopbar';

import './ListLayout.css';


/* function ListPagination(props) {
    const { first, last, prev, next, current } = props;
    return (
        <Pagination>
            <Pagination.First />
            <Pagination.Item>{1}</Pagination.Item>
            <Pagination.Item active>{2}</Pagination.Item>
            <Pagination.Item>{3}</Pagination.Item>
            <Pagination.Next />
        </Pagination>
    )
} */

function createOptionsFilterExtras(items, onSubmitFilter) {
    return items.map(item => {
        return <Form.Control
            as="select"
            className="select-item"
            onChange={(e) => {
                const { value } = e.target;

                if (value === '-1') {
                    onSubmitFilter({
                        column: null,
                        filter: null
                    })

                } else {
                    onSubmitFilter({
                        column: item.column,
                        filter: e.target.value,
                        isOptionFilter: true
                    })
                }

            }}>

            <option value='-1'>{item.tipo}</option>
            {item.data.map(option => <option key={`op-${option[item.label]}`} value={option[item.filter]}>{option[item.label]}</option>)}
        </Form.Control>
    })
}

function ListLayout({ columns, rows, onRowClick, onSubmitFilter, actions, bordered, swipeColor, hover, columnsOptionsFilterExtras, firstColumn, optionInitialFilter, multiFilter }) {

    return (
        <React.Fragment>

            {onSubmitFilter &&
                <div className="option-filter">
                    {columnsOptionsFilterExtras && createOptionsFilterExtras(columnsOptionsFilterExtras, onSubmitFilter)}
                    <ListTopbar {...{ columns, onSubmitFilter, actions, firstColumn, optionInitialFilter, multiFilter }} />
                </div>
            }

            <div className="table-responsive">
                <Table
                    bordered={bordered}
                    striped={swipeColor}
                    hover={hover}
                    responsive
                    bsPrefix="table"
                    className="table table-content"
                >
                    {/* TABLE HEADER */}
                    {(Array(columns).length > 0) &&
                        <thead>
                            <tr>
                                {columns.map((item, idx) => {
                                    const thKey = `th-${idx}`;
                                    const colSpan = item.colSpan || 0;
                                    const className = ['list-header', item.className || ''].join(' ');
                                    const style = {
                                        ...item.onClick ? { cursor: 'pointer' } : {},
                                        ...item.style || {}
                                    };
                                    // const width = item.width ? item.width : {};
                                    const ordered = item.ordered ? item.ordered : '';

                                    return (
                                        <th key={thKey}
                                            onClick={item.onClick}
                                            colSpan={colSpan}
                                            className={className}
                                            style={style}
                                            {...item.width && { width: item.width }}
                                            {...item.attr && item.attr} // atributos extras para table header
                                        >
                                            {item.label}
                                            {ordered}
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                    }

                    {/* TABLE BODY */}
                    <tbody>
                        {rows.length === 0 && (
                            <tr><td colSpan={columns.length}>
                                <NothingToShow />
                            </td></tr>
                        )}

                        {rows.map((row, k) => {
                            const trKey = `tr-${k}`;
                            const className = ['list-row', row.className || ''].join(' ');
                            const style = {
                                ...row.onClick ? { cursor: 'pointer' } : {},
                                ...row.style || {}
                            }

                            return (
                                <tr key={trKey}
                                    onClick={row.onClick}
                                    className={className}
                                    style={style}
                                >
                                    {row.data.map((field, fidx) => {
                                        const tdKey = `${trKey}-td-${fidx}`;
                                        const colSpan = field.colSpan || 0;
                                        const tdClassName = ['list-row-cell', field.className].join(' ');
                                        const tdStyle = {
                                            ...field.onClick ? { cursor: 'pointer' } : {},
                                            ...field.style || {}
                                        }

                                        if (field.actions && field.actions.type === 'checkbox') {
                                            return (
                                                <td key={tdKey}>
                                                    <input
                                                        id={row.data[0].value}
                                                        type={field.actions.type}
                                                        name={row.data[0].value}
                                                        value={row.data[0].value}
                                                        // defaultChecked={field.actions.checked}
                                                        defaultChecked={false}
                                                        onClick={() => field.actions.onclick({
                                                            id: row.data[0].value,
                                                            checked: document.getElementById(row.data[0].value).checked
                                                        })}
                                                    />
                                                </td>
                                            )

                                        }

                                        return (
                                            <td key={tdKey}
                                                onClick={onRowClick || field.onClick}
                                                colSpan={colSpan}
                                                className={tdClassName}
                                                style={tdStyle}
                                                {...field.attr && field.attr}
                                            >
                                                {field.value}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        </React.Fragment>
    )
}

// PROPTYPES
ListLayout.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            className: PropTypes.string,
            colSpan: PropTypes.oneOf([
                PropTypes.string,
                PropTypes.number,
                PropTypes.any
            ]),
            onClick: PropTypes.func,
            style: PropTypes.object,
            ordered: PropTypes.string,
            // width: PropTypes.oneOf([
            //     PropTypes.string,
            //     PropTypes.number,
            //     PropTypes.any
            // ]),
        }),
    ),
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            className: PropTypes.string,
            onClick: PropTypes.func,
            style: PropTypes.object,
            data: PropTypes.arrayOf(
                PropTypes.shape({
                    value: PropTypes.any.isRequired,
                    className: PropTypes.string,
                    colSpan: PropTypes.oneOf([
                        PropTypes.string,
                        PropTypes.number,
                        PropTypes.any
                    ]),
                    onClick: PropTypes.func,
                    style: PropTypes.object
                })
            )
        })
    ),
    onSubmitFilter: PropTypes.func,
    bordered: PropTypes.bool,
    swipeColor: PropTypes.bool,
    hover: PropTypes.bool,
    // actions: PropTypes.oneOfType([
    //     PropTypes.array
    // ]),
};

// DEFAULT PROPS
ListLayout.defaultProps = {
    bordered: false,
    swipeColor: true,
    hover: true,
    onRowClick: null,
    onSubmitFilter: null,
    columns: [],
    rows: [],
    actions: []
};

export default ListLayout;
