/** 
 * Informações das colunas para lista
 * @type {Array<{
 *   label: string,
 *   name: string,
 *   width: string | number,
 *   colSpan: string | number,
 *   className: string,
 *   dynamicClass: function,
 *   style: string
 * }>} 
*/
export const columns = [
    {
        label: '',
        name: 'checkbox',
        width: '1%',
        // className: 'd-none d-md-block w-auto',
    },
    {
        label: 'Key',
        name: 'key',
        width: '15%',
        // className: 'd-none d-md-block w-auto',
    },
    {
        label: 'Nome',
        name: 'name',
        width: '15%',
        // className: 'd-none d-md-block w-auto',
    }
];


const FuncaoListInfo = { columns };

export default FuncaoListInfo;
