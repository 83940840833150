import React, { useCallback, useEffect, useState } from 'react';
import ListLayout from '../../../components/ListLayout';
import Loading from '../../../components/Loading';
import { columns } from './SimulacaoTeamColabInfo';
import ErrorRequestService from '../../../services/errorRequest';
import useAlertNotification from '../../../context/AlertNotificationContext';
import { Accordion, Button, Card } from 'react-bootstrap';
import UtilService from '../../../services/util';

const INITIAL_PAGEPARAMS = {
    orderBy: 'id',
    orderDir: 'asc'
};
const ORDER_DIR_NUM = { asc: 1, desc: -1 };

function ColabListView({item}) {
    // CONTEXT
    const alert = useAlertNotification();

    const [rowsData, setRowsData] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [orderBy, setOrderBy] = useState(INITIAL_PAGEPARAMS.orderBy);
    const [orderDir, setOrderDir] = useState(INITIAL_PAGEPARAMS.orderDir);
    const [isLoading, setIsLoading] = useState(true);

    const getListHeader = () => {
        const headers = [];
        const defineOrder = (item) => {
            if (orderBy === item) {
                const newDir = (orderDir === 'asc') ? 'desc' : 'asc';
                setOrderDir(newDir);
                return;
            }
            setOrderBy(item);
        }
        columns.forEach(item => {
            const waySign = (ORDER_DIR_NUM[orderDir] > 0) ? '▴' : '▾';
            const orderInd = (orderBy === item.name)
                ? {ordered: `${waySign}`}
                : {};
            const head = {
                ...item,
                ...orderInd,
                onClick: () => defineOrder(item.name),
            }
            headers.push(head);
        })
        return headers;
    }

    const defineDataList = (sprintNumber) => {
        const sortRows = (a, b) => {
            if (a[orderBy] < b[orderBy]) return (-1 * ORDER_DIR_NUM[orderDir]);
            if (a[orderBy] > b[orderBy]) return (1 * ORDER_DIR_NUM[orderDir]);
        }
        const dataList = [];
        const rows = filteredData[sprintNumber] || [];
        rows.sort(sortRows)
            .forEach(item => {
                const row = {
                    data: columns.map(col => {
                        if (col.name !== "id" && col.name !== "funcao_nome") {
                            return {
                                value: parseFloat(item[col.name])
                                    .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
                                className: col.className
                            }
                        }
                        return {
                            value: item[col.name],
                            className: col.className
                        }
                    })
                };
                dataList.push(row);
            });
        return dataList;
    }

    // const filterList = (params) => {
    //     const { columns, filter } = params;

    //     if (!columns || !filter) {
    //         setFilteredData(rowsData);
    //         return
    //     }

    //     const filtered = rowsData.filter(item => {
    //         const lwData = String(item[columns]).toLowerCase();
    //         const lwFilter = String(filter).toLowerCase();

    //         return lwData.includes(lwFilter);
    //     });
    //     setFilteredData(filtered);
    // }

    const getDataList = useCallback(async () => {
        setIsLoading(true);

        try {
            const { id ,squadColab } = item.squad;
            setRowsData(squadColab);
            setFilteredData(squadColab);
        } catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();
            console.log(errorMsg);
            alert.error(errorMsg);
        } finally {
            setIsLoading(false);
        }
    }, [])

    const exportDataInExcel = async () => {
        const transformLocaleString = (number) => {
            return parseFloat(number).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        }

        const header = [
            [
                'Nº sprint', 'Quantidade', 'Função', 'Regime', 'Filial', 'Benefícios', 'Impostos',
                'Dependentes', 'Salário', 'Custo Folha', 'Salário Líquido',
                'Custo Total', 'Custo Hora', 'Custo Hora Meio Período',
                'Provisão 13º Salário', 'Provisão Férias', 'Provisão Rescisão'
            ]
        ];
        const all = Object.keys(rowsData)
        let formatedData = [];
        all.map((sprint) =>{
            console.log('sprint', sprint)
            rowsData[sprint].map((item) => {
                    formatedData.push({
                    sprint: item['sprint'],
                    quantidade: item['quantidade'],
                    funcaoNome: item['funcao_nome'],
                    regimeSigla: item['regime_sigla'],
                    filialLabel: item['filial_label'],
                    beneficioTotal: transformLocaleString(item["beneficio_total"]),
                    impostoTotal: transformLocaleString(item["imposto_total"]),
                    dependentes: item['dependentes'],
                    salarioBruto: transformLocaleString(item["salario_bruto"]),
                    slarioFilial: transformLocaleString(item["salario_filial"]),
                    salarioLiquido: transformLocaleString(item["salario_liquido"]),
                    custoTotal: transformLocaleString(item["custo_total"]),
                    custoHH: transformLocaleString(item["custo_hora_colaborador"]),
                    custoHHMeioPeriodo: transformLocaleString(item["custo_hora_colaborador_meio_periodo"]),
                    provisaoDecimoTerceiro: transformLocaleString(item["provisao_decimo_terceiro"]),
                    provisaoFerias: transformLocaleString(item["provisao_ferias"]),
                    provisaoRescisao: transformLocaleString(item["provisao_rescisao"])
                })
            }
            )
        })

        UtilService.exportXls(formatedData, item.squad.nome, header);
    }
    useEffect(() => {
        if (!rowsData) getDataList();

    }, [rowsData, getDataList]);

    return (
            <>
            { isLoading
                ? <Loading message="Carregando..." />
                :
                <>
                    <Button onClick={exportDataInExcel} className="mb-2">Exportar</Button>
                    <Accordion>
                    {Object.keys(filteredData).map(item => (
                        <Card key={`sprint-${item}`}>
                            <Accordion.Toggle as={Card.Header} eventKey={item}>
                            {`Sprint ${Number(item) + 1}`}
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={item}>
                                <ListLayout
                                    columns={getListHeader()}
                                    rows={defineDataList(item)}
                                    />
                            </Accordion.Collapse>
                        </Card>
                    ))}
                    </Accordion>
                </>
            }
            </>
    )
}

export default ColabListView;
