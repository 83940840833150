import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import CustomModal from '../../../../components/CustomModal';
import ListLayout from '../../../../components/ListLayout';
import Loading from '../../../../components/Loading';

import useAlertNotification from '../../../../context/AlertNotificationContext';
import ErrorRequestService from '../../../../services/errorRequest';
import BeneficioApiService from '../../../../services/api/BeneficioApiService';
import UtilService from '../../../../services/util';

import BeneficioView from '../BeneficioView';
import BeneficioValorForm from '../BeneficioValorForm';

import './BeneficioPage.css';

let editable = false;

const filialCols = [
    {
        label: 'Filial',
        name: 'label',
        // width: '35%%',
    },
    {
        label: 'Valor',
        name: 'valor',
    }
];

function BeneficioPage() {
    // CONTEXT
    const match = useRouteMatch();
    const location = useLocation();
    const alert = useAlertNotification();

    const { cod } = match.params;

    // STATE
    const [edit, setEdit] = useState(false);
    const [dados, setDados] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    // FUNCTIONS
    const toggleEdit = (value) => {
        if (edit !== value) setEdit(value);
    }

    const onSaveForms = useCallback(async () => {
        CustomModal.hide();
        setEdit(false);
        await onStart();
    }, []);

    const onCancelForms = useCallback(
        () => CustomModal.hide(),
        []
    );

    const openValorFormModal = useCallback((row) => {
        CustomModal.show({
            body: (
                <BeneficioValorForm
                    dados={row}
                    onSave={() => onSaveForms()}
                    onCancel={() => onCancelForms()}
                    editable={editable}
                />
            )
        });
    }, [onSaveForms, onCancelForms]);

    const getFilialRows = useCallback(() => {
        if (!dados) return [];
        const { valores, filiais } = dados;
        const rowsValorFilial = [...filiais].map(filial => {
            const valObj = valores.find(v => (v.filialCod === filial.cod));
            valObj.valor = UtilService.maskCurrency(valObj.valor);
            return { ...filial, ...valObj } 
        }).filter(r => r !== null);
        const rows = rowsValorFilial.map(item => {
            const row = {
                onClick: () => openValorFormModal(item),
                data: filialCols.map(col => ({
                    value: item[col.name],
                    className: col.className || ''
                }))
            }
            return row;
        });

        return rows;
    }, [dados, openValorFormModal]);

    const onStart = useCallback(async () => {
        try {
            const result = (await BeneficioApiService.getByCod(cod)).data;
            setDados(result.data);// beneficio, valores, filiais
        }
        catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors(); 
            console.log(errorMsg);
            alert.error(errorMsg);
        }
        finally {
            setIsLoading(false);
        }
    }, [cod]);

    const handleEdit = () => {
        editable = edit;
    };

    // USEEFFECTS
    useEffect(onStart, []);
    useEffect(handleEdit, [edit]);

    // RENDER
    const sectionHeadCss = "d-flex justify-content-between mb-3";

    return (
        <div className="beneficio-page">
            { isLoading && <Loading message="Carregando..." />}

            { (!isLoading && dados !== null) && (
                <>
                    <BeneficioView
                        editable={edit}
                        onEditableChange={toggleEdit}
                        dados={dados}
                    />

                    <Row className="appended-list">
                        {/* Valores Filiais */}
                        <Col>
                            <div className={sectionHeadCss}>
                                <h4>Valor por Filial</h4>
                                <Button 
                                    variant="outline-primary" 
                                    onClick={() => openValorFormModal()}
                                    disabled={!edit}
                                >
                                    <FaPlus/> Adic.Filial
                                </Button>
                            </div>
                            <ListLayout
                                key={`filiais-${location.key}`}
                                columns={filialCols}
                                rows={getFilialRows()}
                            />
                        </Col>
                    </Row>
                </>
            )}

            <CustomModal />
        </div>
    )
}


export default BeneficioPage;
