import PageContainer from '../../../components/PageContainer';
import { FaPen, FaPlus, FaTrash } from 'react-icons/fa';
import { useRouteMatch, useHistory } from 'react-router-dom';
import React, { useEffect, useCallback, useState } from 'react';
import Loading from '../../../components/Loading';
import ListLayout from '../../../components/ListLayout';
import { columns } from './SimulacaoTeamInfo';
import CustomModal from '../../../components/CustomModal';
import { FormStateBuilder } from '../../../components/FormBuilder';
import SimulacaoNewTeamInfo from './SimulacaoNewTeamInfo';
import ErrorRequestService from '../../../services/errorRequest';
import { Button, ButtonGroup } from 'react-bootstrap';
import useAuth from '../../../context/AuthContext';
import ColabListView from './ColabListView';
import SquadApiService from '../../../services/api/SquadApiService';
import NewSquadModal from './NewSquadModal';

const INITIAL_PAGEPARAMS = {
    orderBy: 'id',
    orderDir: 'asc'
};
const ORDER_DIR_NUM = { asc: 1, desc: -1 };

function SimulacaoTeamView(){

    const history = useHistory();
    const match = useRouteMatch();

    // CONTEXT
    const { user } = useAuth();
    const { email } = user;

    // STATE
    const [rowsData, setRowsData] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [orderBy, setOrderBy] = useState(INITIAL_PAGEPARAMS.orderBy);
    const [orderDir, setOrderDir] = useState(INITIAL_PAGEPARAMS.orderDir);
    const [isLoading, setIsLoading] = useState(true);

    // STATE
    const [originData, setOrginData] = useState(null);
    const [formFields, setFormFields] = useState(null);
    const [formData, setFormData] = useState(null);

    // FUNCTIONS

    const rowClick = (item) => {
        CustomModal.show({
            title: `Simulação - ${item.squad.nome}`,
            body: <ColabListView item={item}/>,
            footer: (
                <ButtonGroup>
                    <Button variant="outline-info" onClick={() => history.push(`${match.url}/${item.squad.id}`)}><FaPen /> Editar</Button>
                    <Button variant="outline-danger" onClick={() => modalDelete(item)}><FaTrash /> Excluir</Button>
                </ButtonGroup>
            )
        })
    }

    const modalDelete = (item) => {
        CustomModal.show({
            body: `Tem certeza que deseja excluir o time: ${item.squad.nome} ?`,
            footer: (
                <>
                    <Button variant="outline-success" onClick={() => deleteSquad(item.squad.id)}>Sim</Button>
                    <Button variant="outline-danger" onClick={() => CustomModal.hide()}>Cancelar</Button>
                </>
            )
        })
    }


    const getListHeader = () => {
        const headers = [];
        const defineOrder = (item) => {
            if (orderBy === item) {
                const newDir = (orderDir === 'asc') ? 'desc' : 'asc';
                setOrderDir(newDir);
                return;
            }
            setOrderBy(item);
        }
        columns.forEach(item => {
            const waySign = (ORDER_DIR_NUM[orderDir] > 0) ? '▴' : '▾';
            const orderInd = (orderBy === item.name)
                ? {ordered: `${waySign}`}
                : {};
            const head = {
                ...item,
                ...orderInd,
                onClick: () => defineOrder(item.name),
            }
            headers.push(head);
        })
        return headers;
    }
    const defineDataList = () => {
        const sortRows = (a, b) => {
            if (a[orderBy] < b[orderBy]) return (-1 * ORDER_DIR_NUM[orderDir]);
            if (a[orderBy] > b[orderBy]) return (1 * ORDER_DIR_NUM[orderDir]);
        }
        const dataList = [];
        const rows = filteredData || [];
        rows.sort(sortRows)
            .forEach(item => {
                const row = {
                    onClick: () => rowClick(item),
                    data: columns.map(col => {
                        if (col.name !== "nome" && col.name !== "nome") {
                            return {
                                value: parseFloat(item.squad[col.name])
                                    .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
                                className: col.className
                            }
                        }
                            return {
                                value: item.squad[col.name],
                                className: col.className
                            }
                    })
                };
                dataList.push(row);
            });
        return dataList;
    }
    const filterList = (params) => {
        const { column, filter } = params;

        if (!column || !filter) {
            setFilteredData(rowsData);
            return
        }

        const filtered = rowsData.filter(item => {
            const lwData = String(item[column]).toLowerCase();
            const lwFilter = String(filter).toLowerCase();

            return lwData.includes(lwFilter);
        });
        setFilteredData(filtered);
    }
    const getDataList = useCallback(async () => {
        const { email } = user;
        setIsLoading(true);

        try {
            const resp = await SquadApiService.getAll(email).then(r => r.data);
            setRowsData(resp.data);
            setFilteredData(resp.data);


        } catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();
            console.log(errorMsg);
            alert.error(errorMsg);
        } finally {
            setIsLoading(false);

        }
    }, [])

    const onStart = () => {
        try {
            const formFields = SimulacaoNewTeamInfo.getFormFields();
            const formData = FormStateBuilder(formFields );

            setFormFields( formFields );
            setFormData( formData );
            setOrginData( formData );

        }
        catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();
            console.log(errorMsg);
            alert.error(errorMsg);
        }
    }
    const createNewSquad = () =>{

        CustomModal.show({
            title: `Cadastre novo time`,
            body:  formFields &&
                    <NewSquadModal getDataList={getDataList} />
                  ,
        })
    }
    const deleteSquad  = async (id) => {
        const  OBJ = {
            "id": id,
            "ativo": false
          }

          console.log(id, email, OBJ)
          try {
            await SquadApiService.removeSquad(email, OBJ);
          } catch (error) {
              console.log('Erro ao deletar squad');
          }finally{
            CustomModal.hide();
            getDataList();
          }

    }
    useEffect(onStart, []);
    useEffect(() => {
        if (!rowsData) getDataList();
    }, [rowsData, getDataList]);

    const pageActions = [
        {
            label: 'Novo time',
            icon: <FaPlus />,
            color: 'primary',
            type: 'button',
            onClick: ()=> createNewSquad(),
        },
    ]

    const title = 'Lista de simulações de times';
    return (
        <PageContainer
            title={title}
            actions={pageActions}
        >
            { isLoading
                ? <Loading message="Carregando..." />
                :
                <>
                <ListLayout
                    columns={getListHeader()}
                    rows={defineDataList()}
                    onSubmitFilter={filterList}
                />
                </>
            }
        <CustomModal />
        </PageContainer>
    )
}
export default SimulacaoTeamView;
