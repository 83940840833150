import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Form, Button, Col, Row, Card } from 'react-bootstrap';
import { FaMinus, FaPlus } from 'react-icons/fa';

import useAlertNotification from '../../../../context/AlertNotificationContext';
import useAuth from '../../../../context/AuthContext';

import ProjetosApiService from '../../../../services/api/ProjetosApiService';
import ResultCards from './ResultCards';

const DEFAULT_VALUE = '0,00';
const BASE_IMPOSTOS_SEM_RECEITA_BRUTA = '10,15';
const BASE_IMPOSTO_SEM_RESULTADO = '5,10';
const COMISSAO_VENDA = '3,00';
const RATEIO_ADMINISTRATIVO = '12,00';

function MargemForm({ props, onCancel }) {
    const { user } = useAuth();
    const { email } = user;

    const { total: custoHHJira } = props.dataProjectDetail;


    // STATE
    const [isLoading, setIsLoading] = useState(false);
    const [isCalculate, setIsCalculate] = useState(false);
    const [errors, setErrors] = useState({});

    const [cotacoes, setCotacoes] = useState({})

    const [valorPrevistoVenda, setValorPrevistoVenda] = useState(DEFAULT_VALUE);
    const [nomeRelatorio, setNomeRelatorio] = useState('');
    const [custoHH, _] = useState(custoHHJira);

    const [custosIndiretos, setCustosIndiretos] = useState({
        0: {
            descricao: "",
            valor: DEFAULT_VALUE,
            moeda: "BRL",
            cotacao: DEFAULT_VALUE,
        }
    });

    const [formResult, setFormResult] = useState({
        impostos: 0,
        receitaLiquida: 0,
        custoTotalVenda: 0,
        resultadoBruto: 0,
        margemBruta: 0,
        rv: 0,
        rateioAdministrativo: 0,
        despesasOperacionais: 0,
        resultadoAntesIRCSLL: 0,
        impostoSemResultado: 0,
        resultadoLiquido: 0,
        margemLiquida: 0,
        totalCustoIndireto: 0
    });

    const [bases, setBases] = useState({
        baseImpostoSemReceitaBruto: BASE_IMPOSTOS_SEM_RECEITA_BRUTA,
        baseImpostoSemResultado: BASE_IMPOSTO_SEM_RESULTADO,
        baseRV: COMISSAO_VENDA,
        baseRateioAdministrativo: RATEIO_ADMINISTRATIVO
    });

    const onChangeBases = (event) => {
        const { value, name } = event.target;
        setBases({...bases, [name]: mask(value ? value : DEFAULT_VALUE)});
    }

    const onChangeCustosIndiretos = (event, index) => {
        const { value, name } = event.target;

        let newValues = {}

        if (name === "moeda") {
            newValues = { ...newValues, cotacao: cotacoes[value] }
        }

        newValues = name === "valor" || name === "cotacao" ? {...newValues, [name]: mask(value)} : newValues = { ...newValues, [name]: value }

        setCustosIndiretos({
            ...custosIndiretos,
            [index]: { ...custosIndiretos[index], ...newValues }
        })

        setErrors({});
    }


    // CONTEXT
    const alert = useAlertNotification();

    const mask = (value) => {
        value = value.replace('.', '').replace(',', '').replace(/\D/g, '')

        const options = { minimumFractionDigits: 2 }

        const result = new Intl.NumberFormat('pt-BR', options).format(
            parseFloat(value) / 100
        )

        return result
    }

    useEffect(() => {
        document.getElementById("custoPrevistoVenda").focus();
        getCotacoes();
    }, [])

    const getCotacoes = async () => {
        const config = {
            method: 'get',
            url: `https://economia.awesomeapi.com.br/last/USD-BRL,EUR-BRL`
          };

          const result = await axios(config);
          setCotacoes({
              BRL: DEFAULT_VALUE,
              USD: mask(parseFloat(result.data['USDBRL'].bid).toFixed(2)),
              EUR: mask(parseFloat(result.data['EURBRL'].bid).toFixed(2))
          })

    }

    const calculaMargem = async () => {
        try {
            const custoVendaFormat = valorPrevistoVenda.replaceAll('.','').replace(',', '.');

            if (custoVendaFormat <= 0) {
                setErrors({ custoPrevistoVenda: "Informe uma valor de venda maior que zero" });
                return;
            }
            const resp = await ProjetosApiService.calculateMargem({
                custoHH,
                receitaBruta: custoVendaFormat,
                baseRV: parseFloat(bases.baseRV.replace(',', '.') / 100),
                baseImpostoSemResultado: parseFloat(bases.baseImpostoSemResultado.replace(',', '.') / 100),
                baseRateioAdministrativo: parseFloat(bases.baseRateioAdministrativo.replace(',', '.') / 100),
                baseImpostoSemReceitaBruto: parseFloat(bases.baseImpostoSemReceitaBruto.replace(',', '.') / 100),
                custosIndiretos: Object.keys(custosIndiretos).map(key => {
                    return {
                        valor: parseFloat(custosIndiretos[key].valor.replaceAll('.', '').replace(',', '.')),
                        cotacao: custosIndiretos[key].cotacao.replace(',', '.')
                    }
                })
            })
            const result = resp.data.data[0]

            setFormResult({
                impostos: result.totalImpostos,
                receitaLiquida: result.totalReceitaLiquida,
                resultadoBruto: result.totalResultadoBruto,
                custoTotalVenda: result.custoTotalVenda,
                margemBruta: result.totalMargemBruta,
                rv: result.totalRv,
                rateioAdministrativo: result.totalRateioAdministrativo,
                despesasOperacionais: result.totalDespesasOperacionais,
                resultadoAntesIRCSLL: result.totalAntesIRCSLL,
                impostoSemResultado: result.totalImpostoSemResultado,
                resultadoLiquido: result.totalResultadoLiquido,
                margemLiquida: result.totalMargemLiquida,
                totalCustoIndireto: result.totalCustoIndireto
            })

            setIsCalculate(true);

        } catch (error) {
            console.log("[ERROR] [MargemForm.calculaMargem]:", error);
            alert.error("Erro ao calcular a margem do projeto.");
        }
    }

    const salvaRelatorio = async () => {
        try {
            if (!nomeRelatorio.trim()) {
                setErrors({ nomeRelatorio: "Informe o nome do relatório" });
                document.getElementById("nomeRelatorio").focus();

                return;
            }

            const validateCustosIndiretos = Object.keys(custosIndiretos).reduce((validate, key) => {
                if (custosIndiretos[key].descricao === "") {
                    setErrors({...errors, [`custoIndiretoDescricao-${key}`]: 'Descrição não deve estar vazio'});
                    return validate = true;
                }
                return validate;
            }, false)

            if (validateCustosIndiretos) return;

            setIsLoading(true);

            const response = await ProjetosApiService.salvarMargem({
                ...formResult,
                custoHH,
                email,
                receitaBruta: parseFloat(valorPrevistoVenda.replaceAll('.', '').replace(',', '.')),
                nomeRelatorio,
                projeto: props.project,
                periodoInicio: props.periodoInicio,
                periodoFim: props.periodoFim,
                custosIndiretos: Object.keys(custosIndiretos).map(key => {
                    return {
                        ...custosIndiretos[key],
                        valor: custosIndiretos[key].valor.replaceAll('.', '').replace(',', '.'),
                        cotacao: custosIndiretos[key].cotacao.replaceAll('.', '').replace(',', '.')
                    }
                })
            });

            const { status } = response;

            if (status !== 200) {
                alert.error("Erro ao salvar a margem do projeto.");
                return;
            }

            alert.success(`Relatório '${nomeRelatorio}' de margem de projeto salvo com sucesso`);
            setIsLoading(false);
            onCancel();

        } catch (error) {
            console.log("[ERROR] [MargemForm.salvaRelatorio]:", error);
            alert.error("Erro ao salvar a margem do projeto.");
        }
    }

    return (
        <div>
            <Form noValidate>
                <Row style={{ marginBottom: 10 }}>
                    <Col>
                        <Form.Label>Custo Hora Colaborador</Form.Label>
                        <Form.Control
                            name="custoColaborador"
                            type="currency"
                            readOnly={true}
                            value={custoHH.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            autoComplete="off"
                        />
                    </Col>
                    <Col></Col>
                </Row>

                <Row style={{ marginBottom: 10 }}>
                    <Col>
                        <Form.Label>% Impostos s/ Resultado Bruto</Form.Label>
                        <Form.Control
                            name="baseImpostoSemReceitaBruto"
                            type="text"
                            onChange={onChangeBases}
                            value={bases.baseImpostoSemReceitaBruto}
                            autoComplete="off"
                            maxLength={5}
                        />
                    </Col>
                    <Col>
                        <Form.Label>% Impostos antes IR/CSLL</Form.Label>
                        <Form.Control
                            name="baseImpostoSemResultado"
                            type="text"
                            onChange={onChangeBases}
                            value={bases.baseImpostoSemResultado}
                            autoComplete="off"
                            maxLength={5}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Label>% RV</Form.Label>
                        <Form.Control
                            name="baseRV"
                            type="text"
                            onChange={onChangeBases}
                            value={bases.baseRV}
                            autoComplete="off"
                            maxLength={5}
                        />
                    </Col>
                    <Col>
                        <Form.Label>% Rateio Administrativo</Form.Label>
                        <Form.Control
                            name="baseRateioAdministrativo"
                            type="text"
                            onChange={onChangeBases}
                            value={bases.baseRateioAdministrativo}
                            autoComplete="off"
                            maxLength={5}
                        />
                    </Col>
                </Row>


                <Row>
                    <Col>
                        <Form.Label>Valor Previsto Venda</Form.Label>
                        <Form.Control
                            id="custoPrevistoVenda"
                            name="custoPrevistoVenda"
                            type="text"
                            onChange={(e) => {
                                const { value } = e.target;
                                setValorPrevistoVenda(mask(value ? value : DEFAULT_VALUE));
                                setErrors({});
                            }}
                            value={valorPrevistoVenda}
                            autoComplete="off"
                            isInvalid= { !!errors.custoPrevistoVenda }
                        />
                        <Form.Control.Feedback type="invalid">
                            { errors.custoPrevistoVenda }
                        </Form.Control.Feedback>
                    </Col>
                </Row>

                <hr />
                <div className="d-flex justify-content-between">
                    <h5>Custos Indiretos</h5>
                    <div>
                        <Button variant="primary" size="sm"
                            onClick={() => {
                                setCustosIndiretos({
                                    ...custosIndiretos,
                                    [Object.keys(custosIndiretos).length]: {
                                        descricao: "",
                                        valor: DEFAULT_VALUE,
                                        moeda: "BRL",
                                        cotacao: DEFAULT_VALUE,
                                    }
                                })
                            }}
                        >
                            <FaPlus />
                        </Button>
                        {Object.keys(custosIndiretos).length > 1 && (
                            <Button variant="danger" size="sm" className="ml-2"
                                onClick={() => {
                                    const arrTemp = []
                                    Object.keys(custosIndiretos).map(key => {
                                        if (parseInt(key) !== Object.keys(custosIndiretos).length - 1)
                                            arrTemp.push(custosIndiretos[key]);
                                    })
                                    setCustosIndiretos({ ...arrTemp })
                                }}
                            >
                                <FaMinus />
                            </Button>
                        )}
                    </div>
                </div>
                {Object.keys(custosIndiretos).map(key => (
                    <>
                        <Row>
                        <Col>
                            <Form.Label>Descrição</Form.Label>
                            <Form.Control
                                name="descricao"
                                type="text"
                                onChange={(e) => onChangeCustosIndiretos(e, key)}
                                value={custosIndiretos[key].descricao}
                                autoComplete="off"
                                isInvalid= {!!errors[`custoIndiretoDescricao-${key}`]}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors[`custoIndiretoDescricao-${key}`]}
                            </Form.Control.Feedback>
                        </Col>
                        <Col>
                            <Form.Label>Valor</Form.Label>
                            <Form.Control
                                name="valor"
                                type="text"
                                onChange={(e) => onChangeCustosIndiretos(e, key)}
                                value={custosIndiretos[key].valor}
                                autoComplete="off"
                            />
                        </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label>Moeda</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="moeda"
                                    value={custosIndiretos[key].moeda}
                                    onChange={(e) => onChangeCustosIndiretos(e, key)}
                                >
                                    <option value="BRL">Real</option>
                                    <option value="USD">Dolár</option>
                                    <option value="EUR">Euro</option>
                                </Form.Control>
                            </Col>
                            {custosIndiretos[key].moeda !== "BRL" && (
                                <Col>
                                    <Form.Label>Cotação</Form.Label>
                                    <Form.Control
                                        name="cotacao"
                                        type="text"
                                        onChange={(e) => onChangeCustosIndiretos(e, key)}
                                        value={custosIndiretos[key].cotacao}
                                        autoComplete="off"
                                        maxLength={5}
                                    />
                                </Col>
                            )}
                        </Row>
                    </>
                ))}

                <Button style={{ marginTop: 15 }} onClick={() => calculaMargem()} >
                    Calcular
                </Button>
            </Form>

            {
                isCalculate && (
                    <>
                        <ResultCards valorPrevistoVenda={valorPrevistoVenda} custoHH={custoHH} formResult={formResult} />
                        <div className='mt-3'>
                            <span style={{ marginTop: 5 }}>
                                <Row>
                                    <Col style={{ display: 'grid' }}>
                                        <Form.Group>
                                            <Form.Control
                                                id="nomeRelatorio"
                                                name="nomeRelatorio"
                                                placeholder="Nome do relatório"
                                                type="text"
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    setNomeRelatorio(value);
                                                    setErrors({});
                                                }}
                                                value={nomeRelatorio}
                                                autoComplete="off"
                                                isInvalid= {!!errors.nomeRelatorio}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                { errors.nomeRelatorio }
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Button style={{ width: 'fit-content', marginTop: 5 }} onClick={() => salvaRelatorio()} >
                                            Salvar
                                        </Button>
                                    </Col>
                                </Row>

                            </span>
                        </div>

                    </>
                )
            }
        </div>
    )
}

export default MargemForm;
