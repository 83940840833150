import React from 'react';
import { Col, Row } from 'react-bootstrap';

import './FechamentoPage.css';

function FechamentoPageDetail({ fechamento }) {

    const transformLocaleString = (number) => {
        return parseFloat(number).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }

    return (
        <>
            <h4>Custo totais do fechamento</h4>
            <hr />
            <Row>
                <Col>
                    <p><strong>Quantidades de colaboradores: </strong>{fechamento.quantidadeColabs}</p>
                    <p><strong>Custo Total: </strong>{transformLocaleString(fechamento.custoTTotal)}</p>
                    <p><strong>Custo Hora Total: </strong>{transformLocaleString(fechamento.custoHHTotal)}</p>
                </Col>
                <Col>
                    <p><strong>Salário Total: </strong>{transformLocaleString(fechamento.salarioBaseTotal)}</p>
                    <p><strong>Custo Folha Total: </strong>{transformLocaleString(fechamento.salarioFilialTotal)}</p>
                </Col>
                <Col>
                    <p><strong>Prov. Férias Total: </strong>{transformLocaleString(fechamento.provisaoFeriasTotal)}</p>
                    <p><strong>Prov. Rescisão Total: </strong>{transformLocaleString(fechamento.provisaoRescisaoTotal)}</p>
                </Col>
                <Col>
                    <p><strong>Imposto Total: </strong>{transformLocaleString(fechamento.impostoTTotal)}</p>
                    <p><strong>Prov. 13º Sal. Total: </strong>{transformLocaleString(fechamento.provisaoDecimoTerceiroTotal)}</p>
                </Col>
            </Row>
        </>
    )
}

export default FechamentoPageDetail
