import React, { useEffect, useMemo, useState } from "react";
import { MdBrightness6 } from "react-icons/md";
import { Container } from "react-bootstrap";
import PageContainer from "../../components/PageContainer";
import GraficoBarras from "../../components/GraficoBarras";
import useTheme from "../../context/ThemeContext";
import ErrorRequestService from "../../services/errorRequest";
import FechamentoApiService from "../../services/api/FechamentoApiService";
import useAlertNotification from "../../context/AlertNotificationContext";
import CardDashboard from "../../components/CardDashboard";
import useAuth from '../../context/AuthContext';
import "./DashboardPage.css";
import { id } from 'date-fns/locale';

/** Pagina contendo informações iniciais */
function DashboardPage() {
    const themeCtxt = useTheme();
    const [fechamentos, setFechamentos] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [meanData, setMeanData] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(undefined);
    const [selectedNumber, setSelectedNumber] = useState(6);
    


    const { user } = useAuth();

    // acesso aos recursos associados ao user(tokenParsed) via keycloak
    const resource_access = user?.roles['convert-api'] ? user?.roles['convert-api']['roles'] : [];

    const alert = useAlertNotification();

    const getFechamentos = async () => {
        try {
            const resp = await FechamentoApiService.getAll().then(
                (r) => r.data
            );
            setFechamentos(resp.data);

            setSelectedMonth(resp.data.slice(0, 1));
        } catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();
            console.log(errorMsg);
            alert.error(errorMsg);
        }
    };

    const getMediaHH = async () => {
        let dados = [
            // { Mês: "AGO-2020", "Valor médio HH": 19.03 },
        ];
        let erros = [];
        for (const mes of fechamentos) {
            try {
                const resp = await FechamentoApiService.getMediaHH(mes.id).then(
                    (r) => r.data
                );

                dados.push({
                    Mês: mes.label,
                    "Valor médio HH": resp.result,
                });
            } catch (err) {
                const errorService = new ErrorRequestService(err);
                const errorMsg = errorService.getErrors();
                erros.push(mes.label);
            }
        }
        alert.info(`Existem fechamentos sem cálculo:${erros.map(err => (`\n${err}`))}`);
        setMeanData(dados);
    };

    const getTotalDepartamentos = async () => {
        // { departamento: "Financeiro", custo: 80000 },
        const data = [];
        if (selectedMonth) {
            try {
                if (Array.isArray(selectedMonth) && !selectedMonth.length)
                    setSelectedMonth(0);

                const resp = await FechamentoApiService
                                    .getTotalDepartamentos(selectedMonth)
                                    .then((r) => r.data);

                resp.result.forEach((item) => {
                    data.push({
                        ...item,
                        custo: Number(item.custo),
                    });
                });

                setTotalData(data);
            } catch (err) {
                const errorService = new ErrorRequestService(err);
                const errorMsg = errorService.getErrors();
                alert.error(errorMsg);
            }
        }
    };

    const topMeanValue = useMemo(() => {
        if (meanData.length) {
            const topValue = meanData.reduce((result, item) => {
                if (item["Valor médio HH"] >= result["Valor médio HH"]) {
                    return item;
                }
                return result;
            });

            return {
                label: topValue["Mês"],
                value: topValue["Valor médio HH"],
            };
        }
        return undefined;
    }, [meanData]);

    const minorMeanValue = useMemo(() => {
        if (meanData.length) {
            const topValue = meanData.reduce((result, item) => {
                if (item["Valor médio HH"] <= result["Valor médio HH"]) {
                    return item;
                }
                return result;
            });

            return {
                label: topValue["Mês"],
                value: topValue["Valor médio HH"],
            };
        }
        return undefined;
    }, [meanData]);

    useEffect(() => {
        if (resource_access.some(item => item === 'dashboard'))
            getFechamentos();
    }, []);

    useEffect(() => {
        if (fechamentos.length && resource_access.some(item => item === 'dashboard')) {
            getMediaHH();
            setSelectedMonth(fechamentos[0].id);
        }
    }, [fechamentos]);

    useEffect(() => {
        if (resource_access.some(item => item === 'dashboard'))
            getTotalDepartamentos();
    }, [selectedMonth]);
    


    const actions = [
        // {
        //     label: "Novo",
        //     icon: <FaPlus />,
        //     color: "primary",ection se
        //     label: "Salvar",
        //     icon: <FaSave />,
        //     color: "success",
        //     onClick: () => alert("Clicou Salvar!"),
        // },
        // {
        //     label: "Tema",
        //     icon: <MdBrightness6 />,
        //     color: "dark",
        //     onClick: themeCtxt.changeTheme,
        // },
    ];

    return (
        !resource_access.some(item => item === 'dashboard') ? <></>:
        <PageContainer title="Dashboard" actions={actions}>
            <Container className="dashboard">
                <section className="section section-1">
                    <CardDashboard
                        title="Valor médio HH"
                        maior={topMeanValue}
                        menor={minorMeanValue}
                    />
                    
                    <GraficoBarras
                        data={meanData.length <= selectedNumber ? meanData.slice(0, selectedNumber) : meanData.slice(meanData.length - selectedNumber, meanData.length)}
                        title={`Valor médio HH - último${selectedNumber > 1 ? 's' : ''} ${selectedNumber > 1 ? selectedNumber + ' meses' : 'mês'}`}
                        barKey="Mês"
                        dataKey="Valor médio HH"
                        layout="horizontal"
                        variant="blue"
                        styles={{ marginLeft: "-5%" }}
                    >
                        <div className="grafico2 selectbox">
                            <h5>Meses de referência: </h5>
                            <select
                                className="select grafico2"
                                value={selectedNumber}
                                onChange={(e) =>{
                                    let val = Number(e.target.value);
                                    
                                    if(val === 12){
                                        setSelectedNumber(Number(12))
                                    }else if (val === 9){
                                        setSelectedNumber(Number(9))
                                    }else if (val === 6){
                                        setSelectedNumber(Number(6))
                                    }else if (val === 3){
                                        setSelectedNumber(Number(3))
                                    }
                                }
                                    
                                }
                            >
                                {/* {fechamentos.map((item, idx) => ( */}
    
                                <option value={3}>
                                3 meses
                                </option>
                                <option value={6}>
                                6 meses
                                </option>
                                <option value={9}>
                                9 meses
                                </option>
                                <option value={12}>
                                12 meses
                                </option>
                                {/* ))} */}
                            </select>
                        </div>
                    </GraficoBarras>
                </section>
                <section className="section section-2">
                    <GraficoBarras
                        data={totalData.slice(0, 6) || -1}
                        title="Custo Total Bruto por Área"
                        barKey="nome"
                        dataKey="custo"
                        layout="vertical"
                        variant="purple"
                    >
                        <div className="grafico2 selectbox">
                            <h5>Mês de referência: </h5>
                            <select
                                className="select grafico2"
                                value={selectedMonth}
                                onChange={(e) =>
                                    setSelectedMonth(Number(e.target.value))
                                }
                            >
                                {fechamentos.map((item) => (
                                    <option value={item.id} key={item.id}>
                                        {item.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </GraficoBarras>
                </section>
            </Container>
        </PageContainer>
    );
}

export default DashboardPage;
