import { Redirect, Route, Switch } from "react-router"

import Colaborador from './Colaborador'
import BeneficioColaborador from './BeneficioColaborador'

function Importacoes(props) {
    const {match} = props

    return (
        <Switch>
            <Route path={`${match.path}/colaboradores`} component={Colaborador}/>
            <Route path={`${match.path}/beneficios-colaborador`} component={BeneficioColaborador}/>

            <Redirect path="*" to="/"/>
        </Switch>
    )

}

export default Importacoes
