import * as Yup from 'yup';
import ValidationService from './validation';

const schema = Yup.object().shape({
    nome: Yup
        .string()
        .required("Campo obrigatório."),

    receitaBruta: Yup
        .number()
        .required("Campo obrigatório.")
        .moreThan(0, "Valor tem que ser maior que 0.")
})

const schemaCustosIndiretos = Yup.object().shape({

    descricao: Yup
        .string()
        .required("Campo obrigatório."),

    valor: Yup
        .number()
        .required("Campo obrigatório")
        .moreThan(0, "Valor tem que ser maior que 0."),

    moeda: Yup
        .string(),

    cotacao: Yup
        .number()
        .test({
            name: 'real-sem-cotacao',
            message: 'Real precisa ter cotação 0.',
            test: (value, context) => {
                if (context.parent.moeda === "BRL") return value === 0
                return true
            }
        }),
});

async function validate(form, schema) {
    return ValidationService.validate(form, schema);
}

async function castValues(form, schema) {
    return ValidationService.castValues(form, schema);
}

const ProjetoRelatorioValidation = {
    schema,
    schemaCustosIndiretos,
    validate,
    castValues
};

export default ProjetoRelatorioValidation;
