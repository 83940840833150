import React, { useEffect, useState } from 'react';
import { Prompt, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { FaPen, FaSave, FaSpinner, FaTrash } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { FormBuilder, FormStateBuilder } from '../../../../components/FormBuilder';
import PageContainer from '../../../../components/PageContainer';
import Loading from '../../../../components/Loading';
import ErrorRequestService from '../../../../services/errorRequest';
import ColaboradorApiService from '../../../../services/api/ColaboradorApiService';
import ColaboradorValidation from '../../../../services/validation/ColaboradorValidation';
import useAlertNotification from '../../../../context/AlertNotificationContext';
import ColaboradorViewInfo from './ColaboradorViewInfo';
import CustomModal from '../../../../components/CustomModal'
import { Button } from 'react-bootstrap';

function ColaboradorView({ editable=false, onEditableChange }) {
    // CONTEXT
    const match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();
    const alert = useAlertNotification();

    // PROPS
    const { nome, sobrenome } = location.state;
    const { mat } = match.params;

    // STATE
    const [origData, setOrginData] = useState(null);
    const [formFields, setFormFields] = useState({});
    const [formData, setFormData] = useState({});
    const [edit, setEdit] = useState(editable);
    const [isEdited, setIsEdited] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    // FUNCTIONS
    const isFormEdited = () => {
        const jsonOrigData = JSON.stringify(origData);
        const jsonFormData = JSON.stringify(formData);
        return (edit) && (jsonOrigData !== jsonFormData);
    }

    const askForLeave = (cbPositive, cbNegative=()=>{}) => {
        if (cbPositive instanceof Function) {
            const msg = 'Foram feitas alterações!\nDeseja realmente sair e perdê-las?';
            const leave = window.confirm(msg);

            if (leave) cbPositive();
            else cbNegative();
        }
        return false;
    }

    const saveForm = async () => {
        try {
            setIsSaving(true);

            // validar form
            const validation = await ColaboradorValidation.validate(formData);
            if (!validation.isValid) {
                alert.error("Erros no formulário!");
                return;
            }

            // se ok, enviar p/ API
            const resp = await ColaboradorApiService.updateOne(formData.mat, formData);
            if (resp.data) {
                setIsEdited(false);
                setEdit(false);
                alert.success("Dados do colaborador atualizados!");
                const url = `${match.url.replace(`/${mat}`, '')}`;
                history.replace(url, location.state);
            }
        }
        catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();
            console.log(errorMsg);
            alert.error(errorMsg);
        }
        finally {
            setIsSaving(false);
        }
    }

    const modalDeleteColaborador = () => {
        CustomModal.show({
            body: `Tem certeza que deseja excluir esse colaborador?`,
            footer: (
                <>
                    <Button variant="outline-success" onClick={() => deleteColaborador()}>Sim</Button>
                    <Button variant="outline-danger" onClick={() => CustomModal.hide()}>Cancelar</Button>
                </>
            )
        })

    }

    const deleteColaborador = async () => {
        try {
            setIsSaving(true);

            const resp = await ColaboradorApiService.deleteByMat({
                mat: formData.mat,
                ativo: false,
            });
            if (resp.data) {
                alert.success("Colaborador deletado com sucesso!");
                const url = `${match.url.replace(`/${mat}`, '')}`;
                history.replace(url, location.state);
            }
        }
        catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();
            console.log(errorMsg);
            alert.error(errorMsg);
        }
        finally {
            setIsSaving(false);
        }
    }

    const toggleEdit = () => {
        if (isEdited) {
            askForLeave(() => {
                setFormData(origData);
                setEdit(!edit);
            });
            return;
        }
        setEdit(!edit);
    }

    const handleEditable = () => {
        if (editable !== edit && onEditableChange) {
            onEditableChange(edit);
        }
    }

    const handleCancel = () => {
        if (isEdited) {
            askForLeave(() => {
                setIsEdited(false);
                setFormData(origData);
                setEdit(!edit);
            });
            return;
        }

        if (edit) {
            setEdit(!edit);
            return;
        }

        history.goBack();
    }

    const onChange = (ev) => {
        const { value, name } = ev.target;
        const newform = {...formData, [name]: value};
        setFormData(newform);
    }

    const onEdit = () => {
        if (isFormEdited() && !isEdited) setIsEdited(true);
    }

    const onStart = () => {
        const getData = async () => {
            try {
                setIsLoading(true);
                const [ formFields, formData ] = await Promise.all([
                    ColaboradorViewInfo.getFormFields(),
                    ColaboradorApiService.getByMat(mat).then(resp => resp.data)
                ]);
                setFormFields( formFields );
                setFormData( formData );
                setOrginData( formData );
            }
            catch (err) {
                const errorService = new ErrorRequestService(err);
                const errorMsg = errorService.getErrors();
                console.log(errorMsg);
                alert.error(errorMsg);
            }
            finally {
                setIsLoading(false);
            }
        }
        getData();
    }

    // USEEFFECTS
    useEffect(onStart, []);
    useEffect(onEdit, [formData]);
    useEffect(handleEditable, [editable, edit]);

    const actions = [
        ...( !edit ? [
            {
                label: 'Editar',
                icon: <FaPen />,
                color: 'primary',
                type: 'button',
                onClick: toggleEdit
            }
        ]:[
            {
                label: isSaving ? 'Salvando...' : 'Salvar',
                icon: isSaving ? <FaSpinner /> : <FaSave />,
                color: 'success',
                type: 'button',
                disabled: isSaving,
                onClick: saveForm
            }
        ]),
        {
            label: 'Cancelar',
            icon: <MdClose /> ,
            color: 'secondary',
            type: 'button',
            disabled: isSaving,
            onClick: handleCancel
        },
        {
            label: 'Excluir',
            icon: <FaTrash />,
            color: 'danger',
            type: 'button',
            disabled: isSaving,
            onClick: modalDeleteColaborador
        }
    ]

    const title = `Colaborador '${nome} ${sobrenome}'`;

    return (
        <PageContainer
            title={title}
            actions={actions}
        >
            { isLoading
                ? <Loading message="Carregando..." />
                : ( origData &&
                    <FormBuilder
                        formFields={formFields}
                        formData={formData}
                        onChange={onChange}
                        disabledForm={!edit}
                        initialValues={origData}
                        schema={ColaboradorValidation.schema}
                    />
                )
            }

            <Prompt
                when={isEdited}
                message={location => askForLeave(() => {
                    setIsEdited(false);
                    setTimeout(() => {
                        history.push(location.pathname);
                    }, 100);
                })}
            />
        </PageContainer>
    )
}

export default ColaboradorView;
