import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FaRegCalendar, FaRegCalendarCheck } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { useCheckboxState, Checkbox } from "reakit/Checkbox";

import PageContainer from '../../../components/PageContainer';
import ListLayout from '../../../components/ListLayout';
import { columns } from './ProjetosListInfo';
import Loading from '../../../components/Loading';

import ErrorRequestService from '../../../services/errorRequest';
import ProjetosApiService from '../../../services/api/ProjetosApiService';
import UtilService from '../../../services/util';
import { ProjetosListHelpeModal } from './ProjetosListHelpeModal';
import CustomPagination from '../../../components/CustomPagination';

const INITIAL_PAGEPARAMS = {
    orderBy: 'cod',
    orderDir: 'asc'
};

const ORDER_DIR_NUM = { asc: 1, desc: -1 };

function ProjetoslList(props) {
    // CONTEXT
    const match = useRouteMatch();
    const history = useHistory();

    // STATE
    const [rowsData, setRowsData] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [filteringField, setFilteringField] = useState(null); // campo de filtragem
    const [orderBy, setOrderBy] = useState(INITIAL_PAGEPARAMS.orderBy);
    const [orderDir, setOrderDir] = useState(INITIAL_PAGEPARAMS.orderDir);
    const [isLoading, setIsLoading] = useState(true);
    const [isGettingExportData, setIsGettingExportData] = useState(false);
    const [itemsPage, setItemsPage] = useState([]);

    const checkbox = useCheckboxState({ state: [] });

    // FUNCTIONS
    /** Define o clique sobre um registro da lista */
    // const rowClick = (item) => {
    //     history.push(`${match.path}/${item.key}`, item);
    // }

    /** Adiciona click e atributos ao header */
    const getListHeader = () => {
        const headers = [];
        const defineOrder = (item) => {
            if (orderBy === item) {
                const newDir = (orderDir === 'asc') ? 'desc' : 'asc';
                setOrderDir(newDir);
                return;
            }
            setOrderBy(item);
        }
        columns.forEach(item => {
            const waySign = (ORDER_DIR_NUM[orderDir] > 0) ? '▴' : '▾';
            const orderInd = (orderBy === item.name)
                ? {ordered: `${waySign}`}
                : {};
            const head = {
                ...item,
                ...orderInd,
                onClick: () => defineOrder(item.name),
            }
            headers.push(head);
        })
        return headers;
    }

    /** Adequa os dados ao formato para lista */
    const defineDataList = () => {
        const sortRows = (a, b) => {
            if (a[orderBy] < b[orderBy]) return (-1 * ORDER_DIR_NUM[orderDir]);
            if (a[orderBy] > b[orderBy]) return (1 * ORDER_DIR_NUM[orderDir]);
        }
        const dataList = [];
        const rows = filteredData || [];
        rows.sort(sortRows)
            .forEach(item => {
                const row = {
                    // onClick: () => rowClick(item),
                    data: columns.map(col => {
                        if (col.name === 'checkbox') {
                            return {
                                value: <Checkbox {...checkbox} value={item.key} />
                            }
                        }

                        // ---------------- cor/icone bloqueio ----------------
                        if (col.name === 'bloqueio') {
                            return {
                                value: (item[col.name] ? <FaRegCalendarCheck /> : <FaRegCalendar />),
                                className: col.dynamicClass && col.dynamicClass(item[col.name]),
                                attr: {
                                    title: `${item[col.name] ? '' : 'Não '}Bloqueado`,
                                    'data-toggle': 'tooltip',
                                }
                            }
                        }
                        // ---------------- formato date dd/mm/yyyy ----------------
                        if (['dataInicio', 'dataFim'].includes(col.name)) {
                            return {
                                value: UtilService.apiDateToView(item[col.name]),
                                className: col.className
                            }
                        }

                        return {
                            value: item[col.name],
                            className: col.className
                        }
                    })
                }
                dataList.push(row);
            });
        return dataList;
    }

    /* Filtra a lista de dados */
    const filterList = async (params, startAt = 0) => {
        const { column, filter } = params;
        setIsLoading(true);

        if (column === 'checkbox') {
            const filterData = rowsData.filter(item => {
                const exist = checkbox.state.filter(checkItem => checkItem.includes(item.key))
                return exist.length > 0 ? item : null;
            });

            const filtered = filterData.filter(item => item !== null);

            setItemsPage(0);
            setFilteringField(params);
            setIsLoading(false);
            return setFilteredData(filtered);
        }

        if (column !== 'checkbox' && filter !== '') {
            try {
                if (isNaN(startAt)) return;

                const resp = (await ProjetosApiService.filterPaginated(filter, startAt)).data;

                setItemsPage(resp.pages - 1);

                setRowsData(resp.data);
                setFilteredData(resp.data);
            }
            catch (err) {
                const errorService = new ErrorRequestService(err);
                const errorMsg = errorService.getErrors();
                console.log(errorMsg);
                alert(errorMsg);
            }
            finally {
                setIsLoading(false);
                setFilteringField(params);
            }
            return
        }

        getDataList();
    }

    /** Traz as funcoes cadastradas no database  */
    const getDataList = useCallback(async (startAt = 0) => {
        setIsLoading(true);

        try {
            if (isNaN(startAt)) return;

            const resp = (await ProjetosApiService.getAllPaginated(startAt)).data;

            setItemsPage(resp.pages - 1);

            setRowsData(resp.data);
            setFilteredData(resp.data);
        }
        catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();
            console.log(errorMsg);
            alert(errorMsg);
        }
        finally {
            setIsLoading(false);
            setFilteringField(null);
        }
    }, []);

    /** Verifica se os dados está filtrado e vai para a próxima página */
    const getNextPaginationData = async (page) => {
        const paginationPage = page;
        return filteringField ? filterList(filteringField, paginationPage) : getDataList(paginationPage);
    }

    const exportAllListInExcel = async () => {
        setIsGettingExportData(true);
        const header = [['Key', 'Nome']];

        const resp = await ProjetosApiService.getAllProject();
        const { data } = resp.data;

        UtilService.exportXls(data, title, header);
        setIsGettingExportData(false);
    }

    // EFFECTS
    useEffect(() => {
        if (!rowsData) getDataList();
    }, [rowsData, getDataList]);

    const pageActions = [

    ]

    const title = 'Lista de Projetos';

    return (
        <PageContainer
            title={title}
            actions={pageActions}
            infoButton={<ProjetosListHelpeModal />}
        >
            {isLoading
                ? <Loading message="Carregando..." />
                :
                <>
                    <Button
                        disabled={isGettingExportData}
                        className="mr-3 mb-2"
                        onClick={exportAllListInExcel}>Exportar</Button>

                    <Button
                        className="mr-3 mb-2"
                        disabled={checkbox.state.length === 0 && true}
                        onClick={() => {
                            console.log(checkbox.state);
                            history.push(`${match.path}/${checkbox.state}`);
                        }}>
                        Calculo Margem
                    </Button>

                    <Button
                        className="mb-2"
                        disabled={checkbox.state.length <= 1 && true}
                        onClick={() => {
                            history.push(`${match.path}/compare/${checkbox.state}`);
                        }}>
                        Comparar Projetos
                    </Button>

                    <ListLayout
                        columns={getListHeader()}
                        rows={defineDataList()}
                        onSubmitFilter={filterList}
                        firstColumn={filteringField?.column ?? ''}
                        optionInitialFilter='name'
                    />
                </>
            }

            <CustomPagination totalPages={itemsPage} pageNeighbours={1} getNextPaginationData={getNextPaginationData} />
        </PageContainer>
    )
}

export default ProjetoslList;
