import FuncaoApiService from '../../../services/api/FuncaoApiService';
import RegimeApiService from '../../../services/api/RegimeApiService';
import FilialApiService from "../../../services/api/FilialApiService";
import BeneficioApiService from '../../../services/api/BeneficioApiService';
import UtilService from "../../../services/util";
import store from '../../../store';
import { storeFuncoes, storeRegimes, storeFiliais } from "../../../store/actions/dadosActions";

/**
 * Informações das colunas para lista de colaboradores
 * @type {{
 *   groups: Array<{
 *      header: string,
 *      shouldRender: Function,
 *      fields: Array<{
 *        label: string,
 *        placeholder: string,
 *        name: string,
 *        type: string,
 *        size: number,
 *        className: string
 *      }>
 *   }
 * }>}
*/
export const getFormFields = async () => {
    // REDUX
    const { dados } = store.getState();


    const [ funcaoData, regimeData, filialData, beneficioData ] = await Promise.all([
        dados.funcoes || (FuncaoApiService.getAll()).then(resp => resp.data.data),
        dados.regimes || (RegimeApiService.getAll()).then(resp => resp.data.data),
        dados.filiais || (FilialApiService.getAll()).then(resp => resp.data.data),
        BeneficioApiService.calculateAverage(new Date().getMonth() + 1).then(resp => resp.data.data)
    ]);

    // UPDATE REDUX ?
    if (!dados.funcoes) {
        store.dispatch(storeFuncoes(funcaoData))
    }
    if (!dados.regimes) {
        store.dispatch(storeRegimes(regimeData))
    }
    if (!dados.filiais) {
        store.dispatch(storeFiliais(filialData))
    }

    return {
        groups: [
            {
                header: '',
                fields: [
                    {
                        label: 'Salário',
                        placeholder: '',
                        name: 'salario',
                        type: 'currency',
                        defaultValue: 0,
                        min: 0,
                        size: 4,
                    },
                    {
                        label: 'Benefício',
                        placeholder: '',
                        name: 'beneficio',
                        type: 'currency',
                        defaultValue: beneficioData.totalAverage,
                        min: 0,
                        size: 4,
                    },
                    {
                        label: 'Qtd. Dependentes',
                        placeholder: '',
                        name: 'dependentes',
                        type: 'number',
                        defaultValue: 0,
                        min: 0,
                        size: 4,
                    },
                    {
                        label: 'Regime',
                        placeholder: '',
                        name: 'regime',
                        type: 'select',
                        size: 4,
                        options: [
                            { value: '', label: '...' },
                            ...(regimeData
                                .sort((a,b) => UtilService.sortArrCallback(a,b,'nome'))
                                .map(item => ({
                                    value: item.cod,
                                    label: `${item.sigla} - ${item.nome}`
                                }))
                            )
                        ]
                    },
                    {
                        label: 'Função',
                        placeholder: '',
                        name: 'funcao',
                        type: 'select',
                        size: 4,
                        options: [
                            { value: '', label: '...' },
                            ...(funcaoData
                                .sort((a,b) => UtilService.sortArrCallback(a,b,'nome'))
                                .map(item => ({
                                    value: item.cod,
                                    label: `${item.cbo} - ${item.nome}`
                                }))
                            )
                        ]
                    },
                    {
                        label: 'Filial',
                        placeholder: 'Ex: Vertigo Rio',
                        name: 'filial',
                        type: 'select',
                        size: 4,
                        options: [
                            { value: '', label: '...' },
                            ...(filialData
                                .sort((a,b) => UtilService.sortArrCallback(a,b,'label'))
                                .map(item => ({
                                    value: item.cod,
                                    label: item.label
                                })
                            )),
                        ]
                    },
                    // {
                    //     label: 'Squad',
                    //     placeholder: 'Ex: Convert Squad',
                    //     name: 'time',
                    //     type: 'select',
                    //     size: 4,
                    //     options: [
                    //         { value: '', label: '...' },
                    //         ...(myTeams
                    //             .sort((a,b) => UtilService.sortArrCallback(a,b,'name'))
                    //             .map(item => ({
                    //                 value: item.id,
                    //                 label: item.name
                    //             })
                    //         )),
                    //     ]
                    // },
                ]
            }
        ]
    };
}

const SimulacaoNewInfo = { getFormFields };

export default SimulacaoNewInfo;
