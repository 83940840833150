import api from "./api";

const ENDPOINT_BASE = "/import";

function colaborador(data) {
    return api.post(`${ENDPOINT_BASE}/colaborador`, { data });
}

function beneficiosColaborador(data) {
    return api.post(`${ENDPOINT_BASE}/beneficios-colaborador`, { data });
}

const ImportacaoApiService = {
    colaborador,
    beneficiosColaborador
};

export default ImportacaoApiService;
