import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';
import {
    FaCheckCircle,
    FaExclamationTriangle,
    FaInfoCircle,
    FaTimes
} from 'react-icons/fa';
import useAlertNotification from '../../context/AlertNotificationContext';

import "./AlertNotification.css";

const icons = {
    info: () => ({
        component: <FaInfoCircle />,
        style: {
            color: "#34555a",
            backgroundColor: "#77c3d0"
        }
    }),
    error: () => ({
        component: <FaExclamationTriangle />,
        style: {
            color: "#850000",
            backgroundColor: "#ff9e9e"
        }

    }),
    success: () => ({
        component: <FaCheckCircle />,
        style: {
            color: "#168821",
            backgroundColor: "#a5ffae"
        }
    })
};

/**
 * Renderiza um alerta de notificação
 * @param {{
 *   type: "error" | "info" | "success",
 *   message: string,
 *   closeFun: Function
 * }} param0 
 * @returns JSX
 */
function AlertNotification({ type, message, closeFun }) {
    const iconType = icons[type]();
    const msgToShow = message.length > 100
        ? `${message.substr(0, 100)}...`
        : message;

    return (
        <div
            className="alert-notification-container"
            style={iconType.style}
            title={message}
            onClick={closeFun}
        >
            <span className="icon-container">
                {iconType.component}
            </span>

            <div className="message-container">
                {msgToShow}
            </div>

            {closeFun &&
                <span className="close-button">
                    <FaTimes />
                </span>
            }
        </div>
    )
}

AlertNotification.defaultProps = {
    type: "info",
    closeFun: undefined
}

AlertNotification.propTypes = {
    message: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["error", "info", "success"]),
    closeFun: PropTypes.func
}

/**
 * Exibe uma pilha de alertas, baseado nos 
 * alertas a partir do store Redux, aplicando 
 * ánimação de entrada e saída.
 * 
 * @see https://github.com/reactjs/react-transition-group/tree/v1-stable
 */
function AlertContainer() {
    // Redux
    const { alerts } = useSelector(state => state.notification);
    
    // CONTEXT
    const alertContext = useAlertNotification();
    
    return (
        <div className="alerts-container">
            <CSSTransitionGroup
                transitionName="alert-notification"
                transitionEnterTimeout={200}
                transitionLeaveTimeout={300}
            >
                {
                    alerts.map(alerta => (
                        <AlertNotification
                            key={alerta.id}
                            message={alerta.message}
                            type={alerta.typeAlert}
                            closeFun={() => alertContext.remove(alerta.id)}
                        />
                    ))
                }
            </CSSTransitionGroup>
        </div>
    )
}

export default AlertContainer;

