import React, {useEffect, useState} from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import CustomModal from '../../../components/CustomModal';
import { FaSave} from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { FormBuilder, FormStateBuilder } from '../../../components/FormBuilder';
import MargemViewInfo from './MargemViewInfo';
import axios from 'axios';
import ProjetoRelatorioValidation from '../../../services/validation/ProjetoRelatorioValidation';
import useAlertNotification from '../../../context/AlertNotificationContext';

const CustoIndiretoModal = ({ salvarCusto, id, item }) =>{
    const [formData, setFormData] = useState({});
    const [formFields, setFormFields] = useState({});
    const [cotacoes, setCotacoes] = useState({});

    const alert = useAlertNotification();

    const onChange = (ev) => {
        const { value, name } = ev.target;

        let newCotacao = {}
        if (name === "moeda") newCotacao = { cotacao: cotacoes[value] };

        const newform = {...formData, [name]: value, ...newCotacao};
        setFormData(newform);
    }

    const onStart = () => {
        const getData = async () => {
            try {
                const formFields = await MargemViewInfo.getFormFieldsCustosIndiretos();
                const formData = item || { descricao: "", moeda: "BRL", cotacao: 0.00, valor: 0.00, id};
                setFormFields(formFields);
                setFormData(formData);

                getCotacoes();
            } catch (err) {
                console.log(err);
            }
        }
        getData();
    }

    const getCotacoes = async () => {
        const config = {
            method: 'get',
            url: `https://economia.awesomeapi.com.br/last/USD-BRL,EUR-BRL`
        };

        const result = await axios(config);
        setCotacoes({
            BRL: 0.00,
            USD: parseFloat(result.data['USDBRL'].bid).toFixed(2),
            EUR: parseFloat(result.data['EURBRL'].bid).toFixed(2)
        })
    }

    useEffect(onStart, []);

    return (
        <>
            <FormBuilder
                formFields={formFields}
                formData={formData}
                onChange={onChange}
                initialValues={formData}
                schema={ProjetoRelatorioValidation.schemaCustosIndiretos}
            />
            <ButtonGroup className="ml-auto mr-0">
                <Button variant="outline-success" type="button" onClick={async () => {
                    const validation = await ProjetoRelatorioValidation.validate(formData, ProjetoRelatorioValidation.schemaCustosIndiretos)

                    if (!validation.isValid) {
                        alert.error("Formulário possui erros");
                        return;
                    }
                    salvarCusto({ ...formData })
                }}>
                    <FaSave /> Salvar
                </Button>
                <Button variant="outline-danger" onClick={() => CustomModal.hide()}>
                    <MdClose /> Cancelar
                </Button>
            </ButtonGroup>
        </>
    );
}
export default CustoIndiretoModal;
