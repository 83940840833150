import { Card, Col, Row, Button } from 'react-bootstrap';
import UtilService from '../../../services/util';

function SimulacaoDetails({ props }) {

    const transformLocaleString = (number) => {
        return parseFloat(number).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }

    const exportDataInExcel = () => {
        const header = [
            [
                'Função', 'Regime', 'Filial', 'Benefícios', 'Impostos',
                'Dependentes', 'Salário', 'Custo Folha', 'Salário Líquido',
                'Custo Total', 'Custo Hora', 'Custo Hora Meio Período',
                'Provisão 13º Salário', 'Provisão Férias', 'Provisão Rescisão'
            ]
        ];

        const label = `Simulação-cod-${props['id']}`

        const formatedData = {
            funcaoNome: props['funcao_nome'],
            regimeSigla: props['regime_sigla'],
            filialLabel: props['filial_label'],
            beneficioTotal: transformLocaleString(props["beneficio_total"]),
            impostoTotal: transformLocaleString(props["imposto_total"]),
            dependentes: props['dependentes'],
            salarioBruto: transformLocaleString(props["salario_bruto"]),
            slarioFilial: transformLocaleString(props["salario_filial"]),
            salarioLiquido: transformLocaleString(props["salario_liquido"]),
            custoTotal: transformLocaleString(props["custo_total"]),
            custoHH: transformLocaleString(props["custo_hora_colaborador"]),
            custoHHMeioPeriodo: transformLocaleString(props["custo_hora_colaborador_meio_periodo"]),
            provisaoDecimoTerceiro: transformLocaleString(props["provisao_decimo_terceiro"]),
            provisaoFerias: transformLocaleString(props["provisao_ferias"]),
            provisaoRescisao: transformLocaleString(props["provisao_rescisao"])
        }

        UtilService.exportXls([formatedData], label, header);
    }

    return (
        <div>
            <Row>
                <Col>
                    <Button onClick={exportDataInExcel}>Exportar</Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className='p-3 mt-3'>
                        <p><strong>Função: </strong>{props["funcao_nome"]}</p>
                        <p><strong>Regime: </strong>{props["regime_sigla"]}</p>
                        <p className='m-0'><strong>Filial: </strong>{props["filial_label"]}</p>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className='p-3 mt-3'>
                        <p>
                            <strong>Benefício total: </strong>
                            {transformLocaleString(props["beneficio_total"])}
                        </p>
                        <p>
                            <strong>Imposto total: </strong>
                            {transformLocaleString(props["imposto_total"])}
                        </p>
                        <p className='m-0'>
                            <strong>Quantidade de dependentes: </strong>
                            {props['dependentes']}
                        </p>
                    </Card>
                </Col>
                <Col>
                    <Card className='p-3 mt-3'>
                        <p>
                            <strong>Salário bruto: </strong>
                            {transformLocaleString(props["salario_bruto"])}
                        </p>
                        <p>
                            <strong>Salário filial: </strong>
                            {transformLocaleString(props["salario_filial"])}
                        </p>
                        <p className='m-0'>
                            <strong>Salário líquido: </strong>
                            {transformLocaleString(props["salario_liquido"])}
                        </p>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className='p-3 mt-3'>
                        <p>
                            <strong>Custo total: </strong>
                            {transformLocaleString(props["custo_total"])}
                        </p>
                        <p>
                            <strong>Custo HH: </strong>
                            {transformLocaleString(props["custo_hora_colaborador"])}
                        </p>
                        <p className='m-0'>
                            <strong>Custo HH meio período: </strong>
                            {transformLocaleString(props["custo_hora_colaborador_meio_periodo"])}
                        </p>
                    </Card>
                </Col>
                <Col>
                    <Card className='p-3 mt-3'>
                        <p>
                            <strong>Provisão do décimo terceiro: </strong>
                            {transformLocaleString(props["provisao_decimo_terceiro"])}
                        </p>
                        <p>
                            <strong>Provisão das férias: </strong>
                            {transformLocaleString(props["provisao_ferias"])}
                        </p>
                        <p className='m-0'>
                            <strong>Provisão da rescisão: </strong>
                            {transformLocaleString(props["provisao_rescisao"])}
                        </p>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default SimulacaoDetails;
