import React, { useEffect, useState } from 'react';
import { Prompt, useHistory, useRouteMatch } from 'react-router-dom';
import { FaSave, FaCalculator, FaRedo } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { FormBuilder, FormStateBuilder } from '../../../components/FormBuilder';
import Loading from '../../../components/Loading';
import PageContainer from '../../../components/PageContainer';
import useAlertNotification from '../../../context/AlertNotificationContext';
import ErrorRequestService from '../../../services/errorRequest';
import SimulacaoNewInfo from './SimulacaoNewInfo';
import SimulacaoValidation from '../../../services/validation/SimulacaoValidation';
import SimulacaoApiService from '../../../services/api/SimulacaoApiService';
import SimulacaoResult from './SimulacaoResult';
import useAuth from '../../../context/AuthContext';

function SimulacaoNew() {
    // CONTEXT
    const { user } = useAuth();
    const history = useHistory();
    const match = useRouteMatch();
    const alert = useAlertNotification();

    // STATE
    const [origData, setOrginData] = useState(null);
    const [formFields, setFormFields] = useState(null);
    const [formData, setFormData] = useState(null);
    const [formResult, setFormResult] = useState(null);
    const [isEdited, setIsEdited] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isCalculated, setIsCalculate] = useState(false);

    // FUNCTIONS
    const isFormEdited = () => {
        const jsonOrigData = JSON.stringify(origData);
        const jsonFormData = JSON.stringify(formData);
        return (jsonOrigData !== jsonFormData);
    }

    const askForLeave = (cbPositive, cbNegative=()=>{}) => {
        if (cbPositive instanceof Function) {
            const msg = 'Foram adicionados dados!\nDeseja realmente sair e perdê-los?';
            const leave = window.confirm(msg);

            if (leave) cbPositive();
            else cbNegative();
        }
        return false;
    }

    const simulationCalculate = async () => {
        try {
            setIsSaving(true);
            // validar form
            const validation = await SimulacaoValidation.validate(formData)

            //mostra possiveis erros
            if (!validation.isValid) {
                alert.error("Formulário possui erros");
                return;
            }
            const result = await SimulacaoApiService.calculateSimulation(formData);
            setFormResult({
                ...result.data,
                regime: formData.regime,
                filial: formData.filial,
                dependentes: formData.dependentes
            });
            setIsCalculate(true);
        } catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();
            console.log(errorMsg);
            alert.error(errorMsg);
        } finally {
            setIsSaving(false);
        }
    }

    const saveForm = async() => {
        const { email } = user;

        try {
            setIsSaving(true);

            const validation = await SimulacaoValidation.validate(formData)

            //mostra possiveis erros
            if (!validation.isValid) {
                alert.error("Formulário possui erros");
                return;
            }

            if (isEdited && !isCalculated) {
                alert.error("É necessário recalcular antes de salvar");
                return;
            }

            const data = { ...formResult, email };
            data.funcao = formData.funcao;

            const result = await SimulacaoApiService.createNew(data);
            if (result.data.result) {
                setIsEdited(false);
                alert.success("Criado nova simulação");
                history.push( match.path.replace('/new','') );
            }

        } catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();
            console.log(errorMsg);
            alert.error(errorMsg);
        } finally {
            setIsSaving(false);
        }
    }

    const handleCancel = () => {
        if (isEdited) {
            askForLeave(() => {
                setIsEdited(false);
                history.goBack()
            });
            return;
        }
        history.goBack();
    }

    const onChange = (ev) => {
        const { value, name } = ev.target;
        const newform = {...formData, [name]: value};
        setIsCalculate(false);
        setFormData(newform);
    }

    const onEdit = () => {
        if (isFormEdited() && !isEdited) {
            setIsEdited(true);
        }
    }

    const onStart = () => {
        const getData = async () => {
            try {
                setIsLoading(true);
                const formFields = await SimulacaoNewInfo.getFormFields();
                const formData = FormStateBuilder(formFields);
                setOrginData( formData );
                setFormFields( formFields );
                setFormData( formData );
            }
            catch (err) {
                const errorService = new ErrorRequestService(err);
                const errorMsg = errorService.getErrors();
                console.log(errorMsg);
                alert.error(errorMsg);
            }
            finally {
                setIsLoading(false);
            }
        }
        getData();
    }

    // USEEFFECTS
    useEffect(onStart, []);
    useEffect(onEdit, [formData])

    const actions = [
        {
            label: 'Salvar',
            icon: <FaSave />,
            color: 'success',
            type: 'button',
            disabled: !isCalculated || isSaving,
            onClick: saveForm
        },
        {
            label: 'Cancelar',
            icon: <MdClose /> ,
            color: 'danger',
            type: 'button',
            disabled: isSaving,
            onClick: handleCancel
        },
        isCalculated ?
        {
            label: 'Recalcular',
            icon: <FaRedo /> ,
            color: 'info',
            type: 'button',
            disabled: isSaving,
            onClick: simulationCalculate
        } : {
            label: 'Calcular',
            icon: <FaCalculator /> ,
            color: 'dark',
            type: 'button',
            disabled: isSaving,
            onClick: simulationCalculate
        }
    ]

    const title = `Nova simulação de custo de colaborador`;

    return (
        <PageContainer
            title={title}
            actions={actions}
        >
            { isLoading
                ? <Loading message="Carregando..." />
                : ( formData &&
                    <FormBuilder
                        formFields={formFields}
                        formData={formData}
                        onChange={onChange}
                        initialValues={origData}
                        schema={SimulacaoValidation.schema}
                    />
                )
            }

            { isCalculated && <SimulacaoResult formResult={formResult} />}

            <Prompt
                when={isEdited}
                message={location => askForLeave(() => {
                    setIsEdited(false);
                    setTimeout(() => {
                        history.push(location.pathname);
                    }, 100);
                })}
            />
        </PageContainer>
    )
}

export default SimulacaoNew;
