import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import SimulacoesList from './SimulacoesList';
import SimulacaoView from './SimulacaoView';
import SimulacaoNew from './SimulacaoNew';
import SimulacaoTeamView from './SimulacaoTeamView'; 
import SimulacaoTeamDetalhes from './SimulacaoTeamDetalhes';

function Simulacoes() {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}`} exact component={SimulacoesList} />
            <Route path={`${match.path}/new`} exact component={SimulacaoNew} />
            <Route path={`${match.path}/my-teams`} exact component={SimulacaoTeamView} />
            <Route path={`${match.path}/my-teams/:id`} exact component={SimulacaoTeamDetalhes} />
            <Route path={`${match.path}/:id`} exact component={SimulacaoView} />
            <Redirect path="*" to="/" />
        </Switch>
    )
}

export default Simulacoes;
