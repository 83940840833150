import api from "./api";

const BASE_ENDPOINT = '/funcao';

/** Traz uma lista das funcões cadastradss */
function getAll() {
    return api.get(`${BASE_ENDPOINT}/list`);
}

/**
 * Traz dados de uma funcao pelo seu codigo, e se ativo/inativo
 * @param {number | string} cod codigo da funcao
 * @param {number | string} ativo 1=ativo, 0=inativo
 */
function getByCod(cod, ativo) {
    return api.get(
        `${BASE_ENDPOINT}/${cod}`,{
        params: { ativo }
    });
}

/** Cria uma nova funcao no database */
function createNew(form) {
    return api.post(
        `${BASE_ENDPOINT}/create`,
        {...form}
    );
}

/** Atualiza os dados de uma funcao */
function updateOne(cod, form) {
    return api.put(`${BASE_ENDPOINT}/update/${cod}`, { ...form })
}

const FuncaoApiService = {
    getAll,
    getByCod,
    createNew,
    updateOne
}

export default FuncaoApiService;