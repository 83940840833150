/**
 * Informações das colunas para lista de colaboradores
 * @type {{
 *   groups: Array<{
 *      header: string,
 *      fields: Array<{
 *        label: string,
 *        placeholder: string,
 *        name: string,
 *        type: string,
 *        size: number,
 *        className: string
 *      }>
 *   }
 * }>}
*/
export const getFormFields = async () => {
    return {
        groups: [
            {
                header: '',
                fields: [
                    {
                        label: 'Nome do relatório',
                        placeholder: '',
                        name: 'nome',
                        type: 'text',
                        size: 4,
                    },
                    {
                        label: 'Custo Hora Colaborador',
                        placeholder: '',
                        name: 'custoHH',
                        type: 'currency',
                        size: 4,
                        readOnly: true
                    },
                ]

            },
            {
                header: '',
                fields: [
                    {
                        label: '% Impostos s/ Resultado Bruto',
                        placeholder: '',
                        name: 'baseImpostoSemReceitaBruto',
                        type: 'percentage',
                        size: 4,

                    },
                    {
                        label: '% Impostos antes IR/CSLL',
                        placeholder: '',
                        name: 'baseImpostoSemResultado',
                        type: 'percentage',
                        size: 4,
                    },
                ]
            },
            {
                header: '',
                fields: [
                    {
                        label: '% RV',
                        placeholder: '',
                        name: 'baseRV',
                        type: 'percentage',
                        size: 4,
                    },
                    {
                        label: '% Rateio Administrativo',
                        placeholder: '',
                        name: 'baseRateioAdministrativo',
                        type: 'percentage',
                        size: 4,
                    },
                ]
            },
            {
                header: '',
                fields: [
                    {
                        label: 'Valor Previsto Venda',
                        placeholder: '',
                        name: 'receitaBruta',
                        type: 'currency',
                        size: 4,
                    },
                ]
            }
        ]
    };
}

export const columnsCustosIndiretos = [
    {
        label: 'Descrição',
        name: 'descricao'
    },
    {
        label: 'Moeda',
        name: 'moeda'
    },
    {
        label: 'Cotação',
        name: 'cotacao'
    },
    {
        label: 'Valor',
        name: 'valor'
    },
    {
        label: 'Ações',
        name: 'acoes'
    }
]

export const getFormFieldsCustosIndiretos = async () => {
    return {
        groups: [
            {
                header: '',
                fields: [
                    {
                        label: 'Descrição',
                        placeholder: '',
                        name: 'descricao',
                        type: 'text',
                        // size: 4,
                    },
                    {
                        label: 'Moeda',
                        placeholder: '',
                        name: 'moeda',
                        type: 'select',
                        // size: 3,
                        options: [
                            { value: "BRL", label: "Real" },
                            { value: "USD", label: "Dolár" },
                            { value: "EUR", label: "Euro" }
                        ]
                    },
                ]
            },
            {
                header: '',
                fields: [
                    {
                        label: 'Cotação',
                        placeholder: '',
                        name: 'cotacao',
                        type: 'currency',
                        // size: 3,
                    },
                    {
                        label: 'Valor',
                        placeholder: '',
                        name: 'valor',
                        type: 'currency',
                    },
                ]
            }
        ]
    };
}


const MargemViewInfo = { getFormFields, columnsCustosIndiretos, getFormFieldsCustosIndiretos };

export default MargemViewInfo;
