import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

let modalInstance = null;

function CustomModal() {
    const [show, setShow] = useState(false);
    const [content, setContent] = useState({});

    const handleClose = () => {
        setShow(false);
        setContent({});
    }

    const handleShow = ({ title, footer, body }) => {
        setContent({ title, footer, body });
        setShow(true);
    }

    const onStart = () => {
        modalInstance = {
            handleClose,
            handleShow
        }
    }

    useEffect(onStart, [])

    const { title, footer, body } = content;
    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            { title &&
                <Modal.Header closeButton>
                    <Modal.Title>{ title }</Modal.Title>
                </Modal.Header>
            }

            { body &&
                <Modal.Body>
                    { body }
                </Modal.Body>
            }

            { footer &&
                <Modal.Footer>
                    { footer }
                </Modal.Footer>
            }
        </Modal>
    )
}

CustomModal.show = ({ title, footer, body }) => {
    modalInstance.handleShow({ title, footer, body });
}

CustomModal.hide = () => {
    modalInstance.handleClose();
}

export default CustomModal;
