import React, { useState } from 'react';
import { Pagination } from 'react-bootstrap';


function CustomPagination({ totalPages, pageNeighbours, getNextPaginationData }) {
    const [currentPage, setCurrentPage] = useState(0);

    const LEFT_PAGE = 'LEFT';
    const RIGHT_PAGE = 'RIGHT';

    const range = (from, to, step = 1) => {
        let i = from;
        const range = []

        while (i <= to) {
            range.push(i);
            i += step;
        }
        return range;
    }

    const fetchPageNumbers = () => {
        const totalNumbers = (pageNeighbours * 2) + 3;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
            let pages = range(startPage, endPage);

            const hasLeftSpill = startPage > 2;
            const hasRightSpill = (totalPages - endPage) > 1;
            const spillOffset = totalNumbers - (pages.length + 1);

            switch (true) {
              // handle: (1) < {5 6} [7] {8 9} (10)
              case (hasLeftSpill && !hasRightSpill): {
                const extraPages = range(startPage - spillOffset, startPage - 1);
                pages = [LEFT_PAGE, ...extraPages, ...pages];
                break;
              }

              // handle: (1) {2 3} [4] {5 6} > (10)
              case (!hasLeftSpill && hasRightSpill): {
                const extraPages = range(endPage + 1, endPage + spillOffset);
                pages = [...pages, ...extraPages, RIGHT_PAGE];
                break;
              }

              // handle: (1) < {4 5} [6] {7 8} > (10)
              case (hasLeftSpill && hasRightSpill):
              default: {
                pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
                break;
              }
            }

            return [0, ...pages, totalPages];
          }

          return range(0, totalPages)
    }

    const gotoPage = page => {
        setCurrentPage(page)
        getNextPaginationData(page)
    }

    const handleMoveLeft = () => {
        gotoPage(currentPage - (pageNeighbours * 2) - 1);
    }

    const handleMoveRight = () => {
        gotoPage(currentPage + (pageNeighbours * 2) + 1)
    }

    const pages = fetchPageNumbers()

    return (
        <Pagination>
            {pages.map((page, index) => {
                if (page === LEFT_PAGE) return (
                    <Pagination.Prev key={index} onClick={handleMoveLeft} />
                )
                if (page === RIGHT_PAGE) return (
                    <Pagination.Next key={index} onClick={handleMoveRight} />
                )

                return (
                    <Pagination.Item key={index} active={currentPage === page} onClick={() => gotoPage(page)}>{page}</Pagination.Item>
                )
            })}
        </Pagination>
    )
}

export default CustomPagination;
