/** 
 * Informações das colunas para lista
 * @type {Array<{
 *   label: string,
 *   name: string,
 *   width: string | number,
 *   colSpan: string | number,
 *   className: string,
 *   dynamicClass: function,
 *   style: string
 * }>} 
*/
export const columns = [
    {
        label: 'Nome',
        name: 'nome',
        width: '15%',
        // className: 'd-none d-md-block w-auto',
    },
    {
        label: 'Projeto',
        name: 'projeto',
        width: '15%',
        // className: 'd-none d-md-block w-auto',
    }
];


const MargemListInfo = { columns };

export default MargemListInfo;
