import React from "react";
import { Button } from 'react-bootstrap';

import CustomModal from '../../../../components/CustomModal';
import MargemForm from './MargemForm';

function HorasDetail(props) {
    const { isLoading, dataProjectDetail } = props;

    const openUserConfig = () => {
        CustomModal.show({
            title: 'Cálculo de Margem do Projeto',
            body: (
                <MargemForm 
                    onCancel={() => CustomModal.hide()}
                    onSave={() => CustomModal.hide()}
                    props={props}
                />
            )
        })
    }

    return (
        <>
            <div style={{ display: "grid" }}>
                <strong>
                    Horas trabalhadas: { 
                        !isLoading 
                        ? dataProjectDetail?.timeSpentTotal.toLocaleString('en-US')
                        : '...'
                    }
                </strong>

                <strong>
                    Horas faturáveis: { 
                        !isLoading 
                        ? dataProjectDetail?.timeSpentBillable.toLocaleString('en-US')
                        : '...'
                    }
                </strong>
                
                <strong>
                    Custo Total: { 
                        !isLoading 
                        ? dataProjectDetail?.total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) 
                        : '...'
                    }
                </strong>

                {
                    !isLoading && 
                        <span style={{ marginTop: 10 }}>
                            <Button onClick={() => openUserConfig()}>Margem do Projeto</Button>
                        </span>
                }
            </div>

        </>
    );
}

export default HorasDetail;
