import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Image, Dropdown, Button } from 'react-bootstrap';
import useAuth from '../../context/AuthContext';
import userImgDefault from '../../assets/img/user-icon.png';
import { FaCog, FaSignOutAlt, } from 'react-icons/fa';
import CustomModal from '../CustomModal';
import UsuarioForm from '../../pages/Usuario';

import './Avatar.css';

const ID_DD_AVATAR = "dd-avatar";

const AvatarToggle = React.forwardRef( ({ onClick }, ref) => {
    const { user } = useAuth();
    const { avatar_imgref, name: nome, email, usuario } = user;

    return (
        <div
            ref={ref}
            onClick={onClick}
            className="avatar-toggle"
        >
            <Image
                className="avatar-img"
                src={avatar_imgref || userImgDefault}
                alt={nome}
                title={usuario}
                height={48}
                roundedCircle
            />
            <div className="avatar-user">
                <span className="font-weight-bold">
                    {nome}
                </span>
                <span className="text-muted" style={{ fontSize: '0.75rem' }}>
                    {email}
                </span>
            </div>
        </div>
    )
} );

function Avatar({ dropSide }) {
    const { user, signOut } = useAuth();
    const history = useHistory();

    if (!user) return null;

    const openUserConfig = () => {
        CustomModal.show({
            // title: 'Configurações do Usuário',
            body: (
                <UsuarioForm 
                    onCancel={() => CustomModal.hide()}
                    onSave={() => CustomModal.hide()}
                />
            )
        })
    }

    const menus = [
        { // example Link interno
            icon: <FaCog/>,
            label: 'Dados de Usuário',
            onClick: null,
            onClick: openUserConfig
        },
        { divider: true },
        {
            icon: <FaSignOutAlt/>,
            label: 'Sair',
            onClick: () => {
                signOut();
                history.push('/');
            }
        },
    ]

    return (
        <Dropdown drop={dropSide}>
            <Dropdown.Toggle
                as={AvatarToggle}
                id={ID_DD_AVATAR}
            />

            <Dropdown.Menu
                className="avatar-menu"
                alignRight 
            >
                { menus.map((item, i) => {
                    if (!!item.divider){
                        return <Dropdown.Divider key={`avatar-dd-divider-${i}`}/>;
                    }

                    return (
                        <Dropdown.Item
                            key={`avatar-dd-item-${i}`}
                            className="avatar-menu-item"
                            onClick={item.onClick}
                            active={false}
                            {...(!!item.href) 
                                ? item.external
                                    ? { as: 'a', href: item.href, target: '_blank' }
                                    : { as: Link, to: item.href }
                                : { as: Button }
                            }
                        >
                            <span className="menu-item-icon">{ item.icon }</span>
                            <span className="menu-item-label">{ item.label }</span>
                        </Dropdown.Item>
                    )
                })}
            </Dropdown.Menu>

            <CustomModal />

        </Dropdown>
    )
}

Avatar.defaultProps = {
    dropSide: "down"
}

Avatar.propTypes = {
    dropSide: PropTypes.oneOf([ "up", "left", "right", "down" ])
}

export default Avatar;
