import React from "react";
import { Card } from "react-bootstrap";

const cardStyle = {
    margin: 2,
}

const cardContentStyle = {
    display: 'grid',
    padding: 10
}

function ResultCards({ valorPrevistoVenda, custoHH, formResult }) {
    return (
        <>
            <div className="d-flex justify-content-center">
                <h4>Sumário Executivo</h4>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: "1fr 1fr", justifyContent: 'center', marginTop: 5 }}>
                <Card style={{ ...cardStyle }}>
                    <div style={{ ...cardContentStyle }}>
                        <span><strong>Receita Bruta:</strong> R$ {valorPrevistoVenda}</span>
                        <span>Impostos: {formResult?.impostos?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                        <span><strong>Receita Líquida:</strong> {formResult?.receitaLiquida?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                    </div>
                </Card>

                <Card style={{ ...cardStyle }}>
                    <div style={{ ...cardContentStyle }}>
                        <span><strong>Custos de vendas:</strong> {formResult?.custoTotalVenda?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                        <span>Custos Indiretos: R$ {formResult?.totalCustoIndireto?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                        <span>Custo HH: {custoHH?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                    </div>
                </Card>

                <Card style={{ ...cardStyle }}>
                    <div style={{ ...cardContentStyle }}>
                        <span><strong>Resultado Bruto:</strong> {formResult?.resultadoBruto?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                        <span><strong>Margem Bruta:</strong> {formResult?.margemBruta?.toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 })}</span>
                        <span>&nbsp;</span>
                    </div>
                </Card>

                <Card style={{ ...cardStyle }}>
                    <div style={{ ...cardContentStyle }}>
                        <span><strong>Desp. Operacionais:</strong> {formResult?.despesasOperacionais?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                        <span>RV: {formResult?.rv?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                        <span>Rateio Administrativo: {formResult?.rateioAdministrativo?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                    </div>
                </Card>

                <Card style={{ ...cardStyle }}>
                    <div style={{ ...cardContentStyle }}>
                        <span>
                            <strong>Resultado IR/CSLL:</strong> {formResult?.resultadoAntesIRCSLL?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </span>
                        <span>
                            Imposto s/ Resultado: {formResult?.impostoSemResultado?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </span>
                        <span>&nbsp;</span>
                    </div>
                </Card>
                <Card style={{ ...cardStyle, background: '#F3F1F1' }}>
                    <div style={{ ...cardContentStyle }}>
                        <span>
                            <strong>Resultado Líquido:</strong> {formResult?.resultadoLiquido?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        </span>
                        <span>
                            <strong>Margem Líquida:</strong> {formResult?.margemLiquida?.toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 })}
                        </span>
                        <span>&nbsp;</span>
                    </div>
                </Card>
            </div>
        </>
    )
}

export default ResultCards
