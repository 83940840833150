import api from "./api";

const BASE_ENDPOINT = '/jira/projects';

function getAllProject() {
    return api.get(`${BASE_ENDPOINT}`);
}

function getAllPaginated(startAt) {
    return api.get(`${BASE_ENDPOINT}/${startAt}`);
}

function filterPaginated(name, startAt) {
    return api.get(`${BASE_ENDPOINT}/search/${name}/${startAt}`);
}

function getDetailProject(key, initialDate, finalDate) {
    return api.post(`${BASE_ENDPOINT}/${key}/${initialDate}/${finalDate}`,
    {
        key
    });
}

function getTimesProject(key, initialDate, finalDate) {
    return api.get(`${BASE_ENDPOINT}/times/${key}/${initialDate}/${finalDate}`);
}

function salvarMargem(payload) {
    return api.post(
        `${BASE_ENDPOINT}/margem`,
        {...payload}
    );
}

function getAll(email) {
    return api.get(`${BASE_ENDPOINT}/margem/${email}`);
}

function getMargemById(email, id) {
    return api.get(`${BASE_ENDPOINT}/margem/${email}/${id}`);
}

function updateOneMargem(email, form) {
    return api.put(`${BASE_ENDPOINT}/margem/${email}`, { ...form });
}

function deleteMargem(email, payload) {
    return api.patch(
        `${BASE_ENDPOINT}/margem/${email}/change-active`,
        {...payload}
    );
}

function calculateMargem(form) {
    return api.post(`${BASE_ENDPOINT}/margem/simulator`, { ...form });
}

const ProjetosApiService = {
    getAllProject,
    getAllPaginated,
    filterPaginated,
    getDetailProject,
    getTimesProject,
    salvarMargem,
    getAll,
    getMargemById,
    updateOneMargem,
    deleteMargem,
    calculateMargem
}

export default ProjetosApiService;
